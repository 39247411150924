import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import SendOtp from "./Login/SendOtp";
import RenderPage from "./Login/Renderpage";
import Chat from "./Chat/Chat";
import Train from "./Chat/Train";
import DocAnalysis from "./Document Analysis/DocAnalysis";
import ImageAnal from "./Image Analytics/ImageAnal";
import Advance_tool from "./Advance tool/Advance_tool";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./index.css";


const App = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RenderPage />} />
        <Route path="chat" element={<Chat />} />
        <Route path="sendotp" element={<SendOtp />} />
        <Route path="train" element={<Train />}></Route>
        <Route path="/render" element={<RenderPage />} />
        <Route path="/doc_upload" element={<DocAnalysis />} />
        <Route path="/img_analysis" element={<ImageAnal />} />
        <Route path="/advance_tool" element={<Advance_tool />} />
      </Routes>
    </BrowserRouter>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
reportWebVitals();
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./AddDataModal.css";
import { v4 as uuidv4 } from 'uuid';

function AddDataModal({ onClose }) {
    const [authenticated, setAuthenticated] = useState(false);
    const navigate = useNavigate();
    const [activeMenu, setActiveMenu] = useState("File");
    const [headers, setHeaders] = useState([]);
    const [authheader, setAuthHeader] = useState([]);
    const [formData, setFormData] = useState({
        hostname: '',
        username: '',
        password: '',
        database: '',
    });
    const [website, setWebsite] = useState({ webname: '', website: '' });
    const [backend, setBackend] = useState({ name: "", url: "" });
    const [endpoint, setEndpoint] = useState({ name: "", url: "" })
    const [files, setFiles] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const [chatId, setChatId] = useState();

    const cred = JSON.parse(sessionStorage.getItem("cred"));
    const userId = cred.uid;
    const useremail = cred.email;
    const token = sessionStorage.getItem('yariyan_gal');
    let language = sessionStorage.getItem('lang');
    const subscription = sessionStorage.getItem('subpurpose');
    const sanitizedSubscription = subscription.replace(/^\//, ''); // Remove the leading '/'
    console.log("sub : ", sanitizedSubscription);

    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("authenticated");
        if (loggedInUser === "true") {
            setAuthenticated(true);
        } else {
            setAuthenticated(false);
            navigate("/render", { replace: true });
        }
    }, [authenticated, navigate]);

    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setIsDragging(false);
        const droppedFiles = Array.from(event.dataTransfer.files);
        setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
    };
    console.log("file : ", files);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleDatabaseData = async (e) => {
        e.preventDefault();
        console.log('Form Data:', formData);
        let cid = chatId;
        if (!chatId) {
            cid = uuidv4();
            console.log('Generated chatId:', cid);
            setChatId(cid);
        }
        const toastUpload = toast.loading("Please wait database is connecting...");

        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ user_id: userId, email: useremail, chat_id: cid, database: formData })
            };
            const response = await fetch('https://vaani.aionasset.in/databaseConnect', requestOptions);

            if (!response.ok) {
                toast.update(toastUpload, { render: "failed to database connect", type: "error", isLoading: false, autoClose: 2000 });
                throw new Error('Failed to fetch messages');
            }

            const mess = await response.json();
            toast.update(toastUpload, { render: "database successfully connected", type: "success", isLoading: false, autoClose: 1000 });
            console.log('Messages :', mess);
        } catch (error) {
            toast.update(toastUpload, { render: "failed to database connect", type: "error", isLoading: false, autoClose: 2000 });
            console.error('Error fetching messages:', error);
        }

    };

    const handleInputWeb = (e) => {
        const { name, value } = e.target;
        setWebsite({
            ...website,
            [name]: value,
        });
    };

    const handleWebsite = async (e) => {
        e.preventDefault();
        console.log('web data: ', website);

        let cid = chatId;
        if (!chatId) {
            cid = uuidv4();
            console.log('Generated chatId:', cid);
            setChatId(cid);
        }
        const toastUpload = toast.loading("Please wait Connecting...");

        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ user_id: userId, email: useremail, chat_id: cid, website: website })
            };
            const response = await fetch('https://vaani.aionasset.in/webData', requestOptions);

            if (!response.ok) {
                toast.update(toastUpload, { render: "failed to connect!", type: "error", isLoading: false, autoClose: 2000 });
                throw new Error('Failed to fetch messages');
            }

            const mess = await response.json();
            toast.update(toastUpload, { render: "website is connected", type: "success", isLoading: false, autoClose: 500 });
            console.log('mm : ', mess);
        } catch (error) {
            toast.update(toastUpload, { render: "failed to connect!", type: "error", isLoading: false, autoClose: 2000 });
            console.error('Error fetching messages:', error);
        }
    };

    const handleAddHeader = () => {
        setHeaders([...headers, { key: "", value: "" }]);
    };

    const handleRemoveHeader = (index) => {
        const newHeaders = headers.filter((_, i) => i !== index);
        setHeaders(newHeaders);
    };

    const handleHeaderChange = (index, field, value) => {
        const newHeaders = [...headers];
        newHeaders[index][field] = value;
        setHeaders(newHeaders);
    };

    const handleAuthAddHeader = () => {
        setAuthHeader([...authheader, { key: "", value: "" }]);
    };

    const handleAuthRemoveHeader = (index) => {
        const newHeaders = authheader.filter((_, i) => i !== index);
        setAuthHeader(newHeaders);
    };

    const handleAuthHeaderChange = (index, field, value) => {
        const newHeaders = [...authheader];
        newHeaders[index][field] = value;
        setAuthHeader(newHeaders);
    };

    // const handleLocation = async (e) => {
    //     const val = e.target.value;
    //     setLocation(val);
    //     console.log('vvv : ', val);
    // }

    // const handleInputBackend = async (e) => {
    //     const { name, value } = e.target;
    //     setBackend({ ...backend, [name]: value })
    // }

    // const handleBackend = async (e) => {
    //     e.preventDefault();
    //     const mergedData = {
    //         backend,
    //         authheader

    //     };
    //     console.log('Merged Data:', mergedData);
    //     let cid = chatId;
    //     if (!chatId) {
    //         cid = uuidv4();
    //         console.log('Generated chatId:', cid);
    //         setChatId(cid);
    //     }
    //     const toastUpload = toast.loading("Please wait Connecting...");
    //     try {
    //         const requestOptions = {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `Bearer ${token}`
    //             },
    //             body: JSON.stringify({ user_id: userId, email: useremail, chat_id: cid, Backend: mergedData })
    //         };
    //         const response = await fetch('https://vaani.aionasset.in/backEnd', requestOptions);

    //         if (!response.ok) {
    //             toast.update(toastUpload, { render: "failed to connect!", type: "error", isLoading: false, autoClose: 2000 });
    //             throw new Error('Failed to fetch messages');
    //         }

    //         const mess = await response.json();
    //         toast.update(toastUpload, { render: "Data has connected", type: "success", isLoading: false, autoClose: 500 });
    //         console.log('Message : ', mess);
    //     } catch (error) {
    //         toast.update(toastUpload, { render: "failed to connect!", type: "error", isLoading: false, autoClose: 2000 });
    //         console.error('Error fetching messages:', error);
    //     }

    // };

    const handleInputEnd = async (e) => {
        const { name, value } = e.target;
        setEndpoint({ ...endpoint, [name]: value })
    }

    const handleEnd = async (e) => {
        e.preventDefault();
        const mergedData = {
            endpoint,
            headers

        };
        console.log('Merged Data:', mergedData);
        let cid = chatId;
        if (!chatId) {
            cid = uuidv4();
            console.log('Generated chatId:', cid);
            setChatId(cid);
        }
        const toastUpload = toast.loading("Please wait Connecting...");

        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ user_id: userId, email: useremail, chat_id: cid, Backend: mergedData })
            };
            const response = await fetch('https://vaani.aionasset.in/endPointAPI', requestOptions);

            if (!response.ok) {
                toast.update(toastUpload, { render: "failed to connect!", type: "error", isLoading: false, autoClose: 2000 });
                throw new Error('Failed to connect to endpoint');
            }

            const mess = await response.json();
            toast.update(toastUpload, { render: "Api has connected", type: "success", isLoading: false, autoClose: 500 });
            console.log('Message : ', mess);
        } catch (error) {
            toast.update(toastUpload, { render: "failed to connect!", type: "error", isLoading: false, autoClose: 2000 });
            console.error('Error fetching messages:', error);
        }

    };

    const fileUpload = async () => {
        console.log('Uploading files:', files);
        let toastUpload;
        try {
            let cid = chatId;
            if (!chatId) {
                cid = uuidv4();
                console.log('Generated chatId:', cid);
                setChatId(cid);
            }

            // Create a new FormData object
            const formData = new FormData();

            // Loop through each file and append it to the FormData object
            files.forEach((file, index) => {
                const sizeInMB = (file.size / 1024 / 1024).toFixed(2);
                console.log('file size is :', sizeInMB);
                if (sizeInMB < 25) {
                    formData.append(`file_${index}`, file);
                }
                else {
                    toast.error(`File size large: ${file.name}`);
                    throw new Error('Failed to upload file');
                }
            });

            formData.append("user_id", userId);
            formData.append("email", useremail);
            formData.append("chat_id", cid);
            toastUpload = toast.loading("Please wait file is uploading...");

            // Send the files to the backend
            const response = await fetch("https://vaani.aionasset.in/upload_advance", {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}` // Authorization header can be added here
                },
                body: formData, // Send FormData directly as the body
            });

            if (response.ok) {
                const result = await response.json();
                toast.update(toastUpload, { render: "Uploaded Successfully", type: "success", isLoading: false, autoClose: 500 });
                console.log("Files uploaded successfully:", result);
            } else {
                toast.update(toastUpload, { render: "failed to upload file!", type: "error", isLoading: false, autoClose: 2000 });
                console.error("Error uploading files:", response.statusText);
            }
        } catch (error) {
            toast.update(toastUpload, { render: "failed to upload file!", type: "error", isLoading: false, autoClose: 2000 });
            console.error("Error uploading files:", error);
        }
    };

    const renderContent = () => {
        switch (activeMenu) {
            case "File":
                return (
                    <div className="content">
                        <h3>Create New Widgets from Single or Multiple File</h3>
                        <p>Supported file formats: CSV,PDF,DOCX, XLSX, PNG, JPEG.</p>

                        <div className="file-upload">
                            <div
                                className={`file-drop-area ${isDragging ? 'dragging' : ''}`}
                                onDragOver={handleDragOver}
                                onDragLeave={handleDragLeave}
                                onDrop={handleDrop}
                            >
                                <input
                                    type="file"
                                    multiple
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    id="fileInput"
                                />
                                <label htmlFor="fileInput" className="upload-icon">⬆️</label>

                                <p>Drag and Drop file(s) here</p>
                                <p>or</p>
                                <button
                                    className="browse-button"
                                    onClick={() => document.getElementById('fileInput').click()}
                                >
                                    Browse for file(s)
                                </button>
                                <p className="file-size-limit">File size limit: 25MB</p>

                                {files.length > 0 && (
                                    <div className="file-details">
                                        <p>Selected files:</p>
                                        <ul>
                                            {files.map((file, index) => (
                                                <li key={index}>{file.name} - {(file.size / 1024 / 1024).toFixed(2)} MB</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>

                        <button className="continue-button" disabled={!files} onClick={fileUpload}>
                            Continue
                        </button>
                    </div>
                );
            case "Endpoint":
                return (
                    <div className="endpoint-content">
                        <h3>Endpoint</h3>
                        <p>Instantly create a table widget from an endpoint. Requires JSON output and CORS-enabled APIs.</p>
                        <a href="#" className="tutorial-link">▶ Tutorial: How to add a Single API Endpoint</a>

                        <div className="form-group">
                            <label>Name</label>
                            <input type="text" placeholder="Historical Chain TVL" name="name" onChange={handleInputEnd} value={endpoint.name} required />
                        </div>

                        <div className="form-group">
                            <label>Endpoint URL</label>
                            <input type="text" placeholder="https://api.llama.fi/v2/historicalChainTvl" name="url" onChange={handleInputEnd} value={endpoint.url} required />
                        </div>

                        {/* <button className="add-headers-button">+ Add Headers</button> */}
                        <div className="headers-section">
                            <button className="add-headers-button" onClick={handleAddHeader}>+ Add Headers</button>
                            {headers.map((header, index) => (
                                <div key={index} className="header-row">
                                    <input
                                        type="text"
                                        placeholder="Key"
                                        value={header.key}
                                        onChange={(e) => handleHeaderChange(index, "key", e.target.value)}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Value"
                                        value={header.value}
                                        onChange={(e) => handleHeaderChange(index, "value", e.target.value)}
                                    />
                                    <button
                                        className="remove-header-button"
                                        onClick={() => handleRemoveHeader(index)}
                                    >
                                        ✖
                                    </button>
                                </div>
                            ))}
                        </div>

                        <div className="action-buttons">
                            <button className="add-button" onClick={handleEnd}>Add</button>
                        </div>
                    </div>
                );

            // case "CustomBackend":
            //     return (
            //         <div className="endpoint-content">
            //             <h3>Integrate your own backend</h3>
            //             <p>Seamlessly integrate proprietary data and systems for full control over visualizations and data presentation.</p>
            //             {/* <a href="#" className="tutorial-link">▶ Tutorial: How to add a Single API Endpoint</a> */}

            //             <div className="form-group">
            //                 <label>Name</label>
            //                 <input type="text" placeholder="Safety talk Platform" name="name" onChange={handleInputBackend} value={backend.name} required />
            //             </div>

            //             <div className="form-group">
            //                 <label>Backend URL</label>
            //                 <input type="text" placeholder="http://localhost:3000" name="url" onChange={handleInputBackend} value={backend.url} required />
            //             </div>

            //             <div className="headers-section">
            //                 <button className="add-headers-button" onClick={handleAuthAddHeader}>+ Add authentication</button>
            //                 {authheader.map((header, index) => (
            //                     <div key={index} className="header-row">
            //                         <input
            //                             type="text"
            //                             placeholder="Key"
            //                             value={header.key}
            //                             onChange={(e) => handleAuthHeaderChange(index, "key", e.target.value)}
            //                         />
            //                         <input
            //                             type="text"
            //                             placeholder="Value"
            //                             value={header.value}
            //                             onChange={(e) => handleAuthHeaderChange(index, "value", e.target.value)}
            //                         />
            //                         <select className="authHeader" onChange={(e) => handleAuthHeaderChange(index, "location", e.target.value)}>
            //                             <option value="header">Select Location</option>
            //                             <option value="header">Header</option>
            //                             <option value="queryparam">QueryParam</option>
            //                         </select>
            //                         <button
            //                             className="remove-header-button"
            //                             onClick={() => handleAuthRemoveHeader(index)}
            //                         >
            //                             ✖
            //                         </button>
            //                     </div>
            //                 ))}
            //             </div>

            //             <div className="action-buttons">
            //                 <button className="add-button" onClick={handleBackend}>Connect</button>
            //             </div>
            //         </div>
            //     );

            case "Iframe":
                return (
                    <div className="endpoint-content">
                        <h3>Website</h3>
                        <p>Add any website or iframe (e.g. streamlit, plotly dashboards) to your workspace.</p>

                        <div className="form-group">
                            <label>Name</label>
                            <input type="text" name="webname" value={website.webname} onChange={handleInputWeb} placeholder="Website" required />
                        </div>

                        <div className="form-group">
                            <label>Website URL</label>
                            <input type="text" placeholder="https://www.aionasset.in" name="website" onChange={handleInputWeb} value={website.website} required />
                        </div>
                        <div className="action-buttons">
                            <button className="add-button" onClick={handleWebsite}>Add</button>
                        </div>
                    </div>
                );

            case "Databases":
                return (
                    <div className="endpoint-content">
                        <h3>Databases Intigration</h3>
                        <p>Easily integrate your financial data and write SQL scripts directly.</p>
                        <form >

                            <div className="form-group">
                                <label>Hostname/IP</label>
                                <input type="text" placeholder="Host Name/IP" name="hostname" onChange={handleInputChange} value={formData.hostname} required />
                            </div>

                            <div className="form-group">
                                <label>UserName</label>
                                <input type="text" placeholder="UserName" name="username" onChange={handleInputChange} value={formData.username} required />
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <input type="password" placeholder="Password" name="password" onChange={handleInputChange} value={FormData.password} required />
                            </div>
                            <div className="form-group">
                                <label>DataBase Name</label>
                                <input type="text" placeholder="DataBase Name" name="database" onChange={handleInputChange} value={formData.database} required />
                            </div>
                            <div className="action-buttons">
                                <button className="add-button" onClick={handleDatabaseData}>Connect</button>
                            </div>
                        </form>
                    </div>
                );

            default:
                return <p>Select an option from the sidebar to add data.</p>;
        }
    };

    return (
        <div className="add-data-modal">
            <ToastContainer position="top-left" autoClose={3000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
            <button className="close-button" onClick={onClose}>✖</button>
            <div className="sidebar">
                <h2>Add Data</h2>
                <div
                    className={`sidebar-item ${activeMenu === "File" ? "active" : ""}`}
                    onClick={() => setActiveMenu("File")}
                >
                    File
                </div>
                <div
                    className={`sidebar-item ${activeMenu === "Endpoint" ? "active" : ""}`}
                    onClick={() => setActiveMenu("Endpoint")}
                >
                    Endpoint
                </div>
                {/* <div
                    className={`sidebar-item ${activeMenu === "CustomBackend" ? "active" : ""}`}
                    onClick={() => setActiveMenu("CustomBackend")}
                >
                    Custom Backend
                </div> */}
                <div
                    className={`sidebar-item ${activeMenu === "Iframe" ? "active" : ""}`}
                    onClick={() => setActiveMenu("Iframe")}
                >
                    Website
                </div>
                <div
                    className={`sidebar-item ${activeMenu === "Databases" ? "active" : ""}`}
                    onClick={() => setActiveMenu("Databases")}
                >
                    Databases <span className="lock-icon">🔒</span>
                </div>
            </div>
            <div className="content">{renderContent()}</div>
        </div>
    );
}

export default AddDataModal;

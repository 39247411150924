import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from "react-router-dom";
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

function Sidebar() {
    const navigate = useNavigate();
    const [authenticated, setAuthenticated] = useState(false);

    const handleLogout = () => {
        sessionStorage.setItem("authenticated", false);
        setAuthenticated(false);
        navigate("/render");
    };

    return (
        <div className="sidebar">
            <button onClick={handleLogout} className="text-sm">
                <FontAwesomeIcon icon={faSignOutAlt} />
                Logout
            </button>
            <hr></hr>
            <button className="data_conn">Data Connectors</button>
        </div>
    );
}

export default Sidebar;
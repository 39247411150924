import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import './Model.css'; // Modal-specific styles
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SignupModel = ({ closeModal, openModal }) => {
    const navigate = useNavigate();
    const [secure, setSecure] = useState({ user: 'AION@2024$safety', pass: 'Saf@tyAI@14-09-2024' });
    const [formData, setFormData] = useState({
        name: '',
        password: '',
        email: '',
        confirmPassword: '',
        purpose: ''
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        // console.log(formData);
        try {
            if (formData.password === formData.confirmPassword) {
                const response = await fetch('https://vaani.aionasset.in/register', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ...formData, ...secure }),
                });
                const data = await response.json();
                if (data.status === "success") {
                    toast.success(data.message);
                    setTimeout(() => {
                        // closeModal(true);
                        openModal('login');
                        // navigate("/render");
                    }, 4000); // Navigate after delay
                } else {
                    toast.error(data.message);
                }
            } else {
                toast.error("Password does not match");
            }
        } catch (err) {
            console.error("Error:", err);
        }
    };

    const handleCancel = () => {
        setFormData({
            name: '',
            password: '',
            email: '',
            confirmPassword: '',
            purpose: '',
        });
    };

    return (
        <div className="modal-backdrop">
            <ToastContainer position="top-left" autoClose={4000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
            <div className="modal">
                <button className="close-btn" onClick={closeModal}>
                    &times;
                </button>
                <h2>Register</h2>
                <form onSubmit={handleSubmit} className='modal-form'>
                    <div className="form-group">
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            placeholder="Name"
                            required
                            className="modal-input"
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            placeholder="Email"
                            required
                            className="modal-input"
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleInputChange}
                            placeholder="Password"
                            required
                            className="modal-input"
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="password"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleInputChange}
                            placeholder="Confirm Password"
                            required
                            className="modal-input"
                        />
                    </div>
                    <div className="form-group">
                        {/* <label htmlFor="language">Choose Language:</label> */}
                        <select
                            id="purpose"
                            name="purpose"
                            value={formData.purpose}
                            onChange={handleInputChange}
                            className="modal-input language-dropdown"
                            required
                        >
                            <option value="" disabled >Objective</option>
                            <option value="chat">Question/Answer</option>
                            <option value="train">Training</option>
                            <option value="doc_upload">Doc Analysis</option>
                            <option value="img_analysis">Image Analysis</option>
                        </select>
                    </div>
                    <button type="submit" className="auth-button">Submit</button>
                    <button type="button" className="auth-button cancel-btn" onClick={handleCancel}>Cancel</button>
                </form>
                <p className="privacy-policy">
                    By signing up, you agree to our <a href="#">Terms</a> and acknowledge our <a href="#">Privacy Policy</a>.
                </p>
            </div>
        </div>
    );
};

export default SignupModel;

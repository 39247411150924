import React, { useEffect, useState, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faBars, faTimes, faUpload, faPlus } from '@fortawesome/free-solid-svg-icons';
import '../Chat/App.css';
import '../Document Analysis/doc.css';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import rehypeRaw from 'rehype-raw'; // To allow raw HTML rendering

const ImageAnal = () => {
    const navigate = useNavigate();
    const [authenticated, setAuthenticated] = useState(false);
    const [isSidebarVisible, setSidebarVisible] = useState(true);
    const [chatHistory, setChatHistory] = useState([]);
    const [messages, setMessages] = useState([]);
    const [chatId, setChatId] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [analyzeImage, setAnalyzeImage] = useState('');
    const fileInputRef = useRef(null);
    const [imageAnalyses, setImageAnalyses] = useState([]);


    const cred = JSON.parse(sessionStorage.getItem("cred"));
    const userId = cred?.uid;
    const useremail = cred?.email;
    const token = sessionStorage.getItem('yariyan_gal');
    const subscription = sessionStorage.getItem('subpurpose');
    const sanitizedSubscription = subscription.replace(/^\//, ''); // Remove the leading '/'
    console.log("sub : ", sanitizedSubscription);
    let language = sessionStorage.getItem('lang');

    useEffect(() => {
        if (userId) {
            fetchChatHistoryByUserId();
        }
    }, [userId]);

    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("authenticated");
        if (loggedInUser === "true") {
            setAuthenticated(true);
        } else {
            setAuthenticated(false);
            navigate("/render", { replace: true });
        }
    }, [authenticated, navigate]);

    const fetchChatHistoryByUserId = async () => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ user_id: userId, email: useremail, subscription: sanitizedSubscription })
            };
            const response = await fetch('https://vaani.aionasset.in/fetchdata', requestOptions);

            if (!response.ok) {
                throw new Error('Failed to fetch chat history');
            }

            const data = await response.json();
            console.log(data);
            setChatHistory(data);
        } catch (error) {
            console.error('Error fetching chat history:', error);
        }
    };

    const fetchMessagesByChatOrUserId = async (cid) => {
        try {
            setChatId(cid);
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ user_id: userId, email: useremail, chat_id: cid })
            };
            const response = await fetch('https://vaani.aionasset.in/fetchmessage', requestOptions);

            if (!response.ok) {
                throw new Error('Failed to fetch messages');
            }

            const mess = await response.json();
            console.log("history:  ", mess);
            setMessages(mess);
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    const handleLogout = () => {
        try {
            sessionStorage.setItem("authenticated", false);
            setAuthenticated(false);
            navigate("/render");
        } catch (e) {
            console.error("Error occurred during logout:", e);
        }
    };

    const toggleSidebar = () => {
        setSidebarVisible(!isSidebarVisible);
    };

    const handleAnalyseOpenImage = (image) => {
        try {
            console.log('open function call hua');
            setAnalyzeImage(image);
            setIsOpen(true);
        } catch (e) {
            console.log("Error occured : ", e);
        }
    };

    const groupChatsByCid = (chats) => {
        const groupedChats = {};
        chats.forEach(chat => {
            if (!groupedChats[chat.cid]) {
                groupedChats[chat.cid] = [];
            }
            groupedChats[chat.cid].push(chat);
        });
        return groupedChats;
    };

    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);

    const todayChats = chatHistory.filter(chat => new Date(chat.res_time).toDateString() === today.toDateString());
    const sevenDaysChats = chatHistory.filter(chat => {
        const chatDate = new Date(chat.res_time);
        return chatDate >= sevenDaysAgo && chatDate.toDateString() !== today.toDateString();
    });
    const olderChats = chatHistory.filter(chat => new Date(chat.res_time) < sevenDaysAgo);

    todayChats.sort((a, b) => new Date(b.res_time) - new Date(a.res_time));

    const groupedTodayChats = groupChatsByCid(todayChats);
    const groupedSevenDaysChats = groupChatsByCid(sevenDaysChats);
    const groupedOlderChats = groupChatsByCid(olderChats);

    const sortByLatestChatTime = (groupedChats) => {
        return Object.keys(groupedChats)
            .map(cid => ({ cid, chats: groupedChats[cid] }))
            .sort((a, b) => new Date(b.chats[0].res_time) - new Date(a.chats[0].res_time));
    };

    const sortedTodayChats = sortByLatestChatTime(groupedTodayChats);
    const sortedSevenDaysChats = sortByLatestChatTime(groupedSevenDaysChats);
    const sortedOlderChats = sortByLatestChatTime(groupedOlderChats);

    const [dropdownVisible, setDropdownVisible] = useState(null);

    const toggleDropdown = (cid) => {
        setDropdownVisible(dropdownVisible === cid ? null : cid);
    };

    const handleChatAction = (action, chatId) => {
        if (action === "delete") {
            deleteChat(chatId);
        }
        setDropdownVisible(null);
    };

    const deleteChat = async (cid) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ user_id: userId, chat_id: cid, email: useremail })
            };
            const response = await fetch('https://vaani.aionasset.in/deleteChat', requestOptions);

            if (!response.ok) {
                throw new Error('Failed to delete chat');
            }

            const mess = await response.json();
            toast.error(mess.response);
            setTimeout(() => {
                // setQuest([]);
                // setChats([]);
                setMessages([]);
                fetchChatHistoryByUserId();
            }, 2000);
        } catch (error) {
            console.error('Error deleting chat:', error);
        }
    };

    const handleNewChat = () => {
        const newChatId = uuidv4();
        console.log('new chat : ', newChatId);
        setChatId(newChatId);
        // setQuest([]);
        // setChats([]);
        setMessages([]);
        setImageAnalyses([]);
    };

    const handleLangChange = async (e) => {
        e.preventDefault();
        sessionStorage.setItem("lang", e.target.value);
    }

    const handleNavigation = async (e) => {
        e.preventDefault();
        sessionStorage.setItem("subpurpose", e.target.value);
        navigate('/' + e.target.value);
    }

    language = sessionStorage.getItem('lang');

    const handleImageUpload = async (event) => {
        const toastgen = toast.loading("Please wait...");
        console.log('chatid : ', chatId);
        let cid
        if (chatId == '' || chatId == null || chatId == undefined) {
            cid = uuidv4();
            setChatId(cid);
        }
        else {
            cid = chatId;
        }
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            try {
                const language = sessionStorage.getItem('lang');
                const formData = new FormData();
                formData.append('file', file);
                formData.append('user_id', userId);
                formData.append('chat_id', cid);
                formData.append('email', useremail);
                formData.append('language', language);
                formData.append('subscription', sanitizedSubscription)

                console.log('ccc: ', cid);

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`  // No need for 'Content-Type', FormData sets it automatically
                    },
                    body: formData
                };

                const response = await fetch('https://vaani.aionasset.in/imgUpload', requestOptions);

                if (!response.ok) {
                    throw new Error('Failed to upload image');
                }

                const mess = await response.json();
                console.log('mess : ', mess);
                const newAnalysis = {
                    id: Date.now(),
                    image: URL.createObjectURL(file),  // Displaying the uploaded image in the frontend
                    response: mess.response
                };
                setImageAnalyses(prev => [newAnalysis, ...prev]);
                toast.update(toastgen, { render: "Success!", type: "success", isLoading: false, autoClose: 500 });

            } catch (error) {
                console.error('Error uploading image response:', error);
                toast.update(toastgen, { render: "Failed to analysis response!", type: "error", isLoading: false, autoClose: 2000 });
            }
        } else {
            // toast.error('Please select a valid image file.');
            toast.update(toastgen, { render: "Please select a valid image file.!", type: "error", isLoading: false, autoClose: 2000 });
        }
    };


    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    function formatResponse(response) {
        // Replace both "CRITICAL WARNING:" and "ଗୁରୁତର ଚେତାବନୀ:" with a styled markdown equivalent
        const formattedResponse = response
            .replace(/CRITICAL WARNING:/g, '<span class="critical-warning">CRITICAL WARNING:</span>') //english
            .replace(/ଗୁରୁତର ଚେତାବନୀ:/g, "<span class ='critical-warning'>ଗୁରୁତର ଚେତାବନୀ:</span>") // odia
            .replace(/गंभीर चेतावनी:/g, '<span class ="critical-warning">गंभीर चेतावनी:</span>') //hindi
            .replace(/সমালোচনামূলক সতর্কতা:/g, '<span class ="critical-warning">সমালোচনামূলক সতর্কতা:</span>') //bangali
            .replace(/క్రిటికల్ వార్నింగ్:/g, '<span class ="critical-warning">క్రిటికల్ వార్నింగ్:</span>') //telgu
            .replace(/गंभीर चेतावणी:/g, '<span class ="critical-warning"> गंभीर चेतावणी:</span>') //marathi
            .replace(/சிக்கலான எச்சரிக்கை:/g, '<span class ="critical-warning">சிக்கலான எச்சரிக்கை:</span>') //tamil
            .replace(/જટિલ ચેતવણી:/g, '<span class ="critical-warning">જટિલ ચેતવણી:</span>') //gujarati
            .replace(/ಕ್ರಿಟಿಕಲ್ ವಾರ್ನಿಂಗ್:/g, '<span class ="critical-warning">ಕ್ರಿಟಿಕಲ್ ವಾರ್ನಿಂಗ್:</span>') //Kanada
            .replace(/Caution:/g, '<span class="caution-warning">Caution:</span>')
            .replace(/ସତର୍କତା:/g, '<span class="caution-warning">ସତର୍କତା:</span>')
            .replace(/सावधानी:/g, '<span class="caution-warning">सावधानी:</span>')
            .replace(/সতর্কতা:/g, '<span class="caution-warning">সতর্কতা:</span>')
            .replace(/జాగ్రత్త:/g, '<span class="caution-warning">జాగ్రత్త:</span>')
            .replace(/सावधगिरी:/g, '<span class="caution-warning">सावधगिरी:</span>')
            .replace(/எச்சரிக்கை:/g, '<span class="caution-warning">எச்சரிக்கை:</span>')
            .replace(/સાવધાન:/g, '<span class="caution-warning">સાવધાન:</span>')
            .replace(/ಎಚ್ಚರಿಕೆ:/g, '<span class="caution-warning">ಎಚ್ಚರಿಕೆ:</span>')

        return formattedResponse;
    }

    return (
        <div className="flex h-screen bg-gray-100">
            <ToastContainer
                position="top-left"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />

            {/* Collapsible Sidebar */}
            <div className={`${isSidebarVisible ? 'w-64' : 'w-16'} bg-gray-800 text-white transition-all duration-300 ease-in-out overflow-y-auto`}>
                <div className="p-4 flex justify-between items-center">
                    <button onClick={toggleSidebar} className="text-white">
                        <FontAwesomeIcon icon={isSidebarVisible ? faTimes : faBars} />
                    </button>
                    {isSidebarVisible && (
                        <button onClick={handleLogout} className="text-sm">
                            <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                            Logout
                        </button>
                    )}
                </div>
                {isSidebarVisible && (
                    <div className="p-4">
                        <button
                            onClick={handleNewChat}
                            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mb-4 flex items-center justify-center"
                        >
                            <FontAwesomeIcon icon={faPlus} className="mr-2" />
                            New Chat
                        </button>
                        <select id="path"
                            name="path"
                            className="mb-4 w-full p-2 bg-gray-700 text-white rounded"
                            onChange={handleNavigation}>
                            <option value={subscription}>Navigate To</option>
                            <option value="chat">Question/Answer</option>
                            <option value="doc_upload">Document Analysis</option>
                            {/* <option value="train">Trainig site</option> */}
                        </select>
                        <select id="language"
                            name="language"
                            className="mb-4 w-full p-2 bg-gray-700 text-white rounded"
                            onClick={handleLangChange}>
                            <option value={language}>Language</option>
                            <option value="hi">Hindi</option>
                            <option value="en">English</option>
                            <option value="bn">Bengali</option>
                            <option value="te">Telugu</option>
                            <option value="mr">Marathi</option>
                            <option value="ta">Tamil</option>
                            <option value="gu">Gujarati</option>
                            <option value="kn">Kannada</option>
                            <option value="or">Odia</option>
                        </select>
                        {sortedTodayChats.length > 0 && (
                            <>
                                <h4 className="font-bold">Today:</h4>
                                {sortedTodayChats.map(({ cid, chats }) => (
                                    <div key={cid} className="chat-item relative">
                                        <div className="chat-message-container">
                                            <p className="chat-heading" onClick={() => fetchMessagesByChatOrUserId(cid)}>
                                                {chats[chats.length - 1].question}
                                            </p>
                                            <span className="dots-icon" onClick={() => toggleDropdown(cid)}>&#x2026;</span>
                                        </div>
                                        {dropdownVisible === cid && (
                                            <div className="dropdown-menu">
                                                <ul className="py-1">
                                                    <li onClick={() => handleChatAction("delete", cid)}>Delete</li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </>
                        )}

                        {/* Repeat similar structure for sevenDaysChats and olderChats */}
                        {sortedSevenDaysChats.length > 0 && (
                            <>
                                <h4 class="font-bold">Previous 7 Day :</h4>
                                {sortedSevenDaysChats.map(({ cid, chats }) => (
                                    <div key={cid} className="chat-item" style={{ position: "relative" }}>
                                        {/* Flex container for message and three-dot icon */}
                                        <div className="chat-message-container">
                                            {/* Chat message heading */}
                                            <p className="chat-heading" onClick={() => fetchMessagesByChatOrUserId(cid)}>
                                                {chats[0].question}
                                            </p>

                                            {/* Three dots icon for dropdown */}
                                            <span className="dots-icon" onClick={() => toggleDropdown(cid)}>
                                                &#x2026;
                                            </span>
                                        </div>

                                        {/* Dropdown menu */}
                                        {dropdownVisible === cid && (
                                            <div className="dropdown-menu">
                                                <ul>
                                                    {/* <li onClick={() => handleChatAction("rename", cid)}>Rename</li> */}
                                                    <li onClick={() => handleChatAction("delete", cid)}>Delete</li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </>
                        )}

                        {sortedOlderChats.length > 0 && (
                            <>
                                <h4 class="font-bold">Older Chat</h4>
                                {sortedOlderChats.map(({ cid, chats }) => (
                                    <div key={cid} className="chat-item" style={{ position: "relative" }}>
                                        {/* Flex container for message and three-dot icon */}
                                        <div className="chat-message-container">
                                            {/* Chat message heading */}
                                            <p className="chat-heading" onClick={() => fetchMessagesByChatOrUserId(cid)}>
                                                {chats[0].question}
                                            </p>

                                            {/* Three dots icon for dropdown */}
                                            <span className="dots-icon" onClick={() => toggleDropdown(cid)}>
                                                &#x2026;
                                            </span>
                                        </div>

                                        {/* Dropdown menu */}
                                        {dropdownVisible === cid && (
                                            <div className="dropdown-menu">
                                                <ul>
                                                    {/* <li onClick={() => handleChatAction("rename", cid)}>Rename</li> */}
                                                    <li onClick={() => handleChatAction("delete", cid)}>Delete</li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                )}
            </div>

            {/* Main Content */}
            <div className="flex-1 flex flex-col p-4">
                <div className="mb-4">
                    <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleImageUpload}
                        accept="image/*"
                        className="hidden"
                    />
                    <button
                        onClick={triggerFileInput}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                    >
                        <FontAwesomeIcon icon={faUpload} className="mr-2" />
                        Upload Image
                    </button>
                </div>

                <div className="flex-1 overflow-auto">
                    {imageAnalyses.length === 0 ? (
                        <p className="text-gray-500">Upload an image to see the analysis result.</p>
                    ) : (
                        imageAnalyses.map(analysis => (
                            <div key={analysis.id} className="bg-white rounded-lg shadow p-4 mb-4 flex">
                                <div className="w-1/2" onClick={() => handleAnalyseOpenImage(analysis.image)}>
                                    <img
                                        src={analysis.image}
                                        alt="Uploaded"
                                        className="max-w-full max-h-[30vh] object-contain rounded-lg shadow-lg cursor-pointer"
                                    />
                                </div>
                                <div className="w-full overflow-auto">
                                    <h3 className="text-lg font-semibold mb-2">Image Analysis :</h3>

                                    <ReactMarkdown
                                        remarkPlugins={[remarkGfm]}
                                        rehypePlugins={[rehypeRaw]} // This enables HTML parsing
                                        children={formatResponse(analysis.response)}
                                    />
                                </div>
                            </div>
                        ))
                    )}
                    {messages.map(mess => (
                        <div key={mess.mid} className="bg-white rounded-lg shadow p-4 mb-4 flex">
                            <div className="w-1/2" onClick={() => handleAnalyseOpenImage(mess.analyzeImg)}>
                                <img
                                    src={mess.analyzeImg}
                                    alt="Uploaded"
                                    className="max-w-full max-h-[30vh] object-contain rounded-lg shadow-lg cursor-pointer"
                                />
                            </div>
                            <div className="w-full overflow-auto">
                                <h3 className="text-lg font-semibold mb-2">Image Analysis:</h3>
                                <ReactMarkdown
                                    remarkPlugins={[remarkGfm]}
                                    rehypePlugins={[rehypeRaw]} // This enables HTML parsing
                                    children={formatResponse(mess.response)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={analyzeImage}
                        onCloseRequest={() => setIsOpen(false)}
                    />
                )}
            </div>


            <style jsx>{`
                .critical-warning {
                    color: red;
                    font-weight: bold;
                    background-color: #ffe6e6;
                    padding: 0.2em;
                    border-radius: 4px;
                }
                .caution-warning {
                    color: orange;
                    font-weight: bold;
                    background-color: #fff4e5;
                    padding: 0.2em;
                    border-radius: 4px;
    }
            `}</style>
        </div>

    );
};

export default ImageAnal;
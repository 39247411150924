import React, { Suspense, useEffect, useRef, useState, useMemo } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { useGLTF, useTexture, Loader, Environment, useFBX, useAnimations, OrthographicCamera } from '@react-three/drei';
import { MeshStandardMaterial } from 'three/src/materials/MeshStandardMaterial';
import { LinearEncoding, sRGBEncoding } from 'three';
import { LineBasicMaterial, MeshPhysicalMaterial, Vector2 } from 'three';
import ReactAudioPlayer from 'react-audio-player';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import createAnimation from '../converter';
import blinkData from '../blendDataBlink.json';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { v4 as uuidv4 } from 'uuid';
import './App.css';
import * as THREE from 'three';
import axios from 'axios';
import { json, useNavigate } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'; // To handle tables and more
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faBars, faTimes, faPlus, faPlay, faPause, faCheck } from '@fortawesome/free-solid-svg-icons';
import 'react-image-lightbox/style.css'; // Import the Lightbox styles
// import { FaClipboard, FaClipboardCheck } from "react-icons/fa"; // For icons
import { faCopy } from "@fortawesome/free-regular-svg-icons"; // Copy icon from regular style
// import { faCheck } from "@fortawesome/free-solid-svg-icons";
// import { faPlay, faPause } from "@fortawesome/free-solid-svg-icons";

const _ = require('lodash');
const host = 'https://vaani.aionasset.in'

function Avatar({ avatar_url, speak, setAudioSource, playing, blendData, setClipActions }) {

  let gltf = useGLTF(avatar_url);
  let morphTargetDictionaryBody = null;
  let morphTargetDictionaryLowerTeeth = null;
  const BlendData = blendData.blendData
  let filename = blendData.filename

  const [bodyTexture, eyesTexture, teethTexture, bodySpecularTexture, bodyRoughnessTexture, bodyNormalTexture, teethNormalTexture, hairTexture, tshirtDiffuseTexture, tshirtNormalTexture, tshirtRoughnessTexture, hairAlphaTexture, hairNormalTexture, hairRoughnessTexture

  ] = useTexture(["/images/body.webp", "/images/eyes.webp", "/images/teeth_diffuse.webp", "/images/body_specular.webp", "/images/body_roughness.webp", "/images/body_normal.webp", "/images/teeth_normal.webp", "/images/h_color.webp",
    "/images/tshirt_diffuse.webp", "/images/tshirt_normal.webp", "/images/tshirt_roughness.webp", "/images/h_alpha.webp", "/images/h_normal.webp", "/images/h_roughness.webp",]);

  _.each([bodyTexture, eyesTexture, teethTexture, teethNormalTexture, bodySpecularTexture, bodyRoughnessTexture, bodyNormalTexture, tshirtDiffuseTexture, tshirtNormalTexture,
    tshirtRoughnessTexture, hairAlphaTexture, hairNormalTexture, hairRoughnessTexture], t => { t.encoding = sRGBEncoding; t.flipY = false; });

  bodyNormalTexture.encoding = LinearEncoding;
  tshirtNormalTexture.encoding = LinearEncoding;
  teethNormalTexture.encoding = LinearEncoding;
  hairNormalTexture.encoding = LinearEncoding;

  gltf.scene.traverse(node => {
    if (node.type === 'Mesh' || node.type === 'LineSegments' || node.type === 'SkinnedMesh') {
      node.castShadow = true;
      node.receiveShadow = true;
      node.frustumCulled = false;

      if (node.name.includes("Body")) {

        node.castShadow = true;
        node.receiveShadow = true;

        node.material = new MeshPhysicalMaterial();
        node.material.map = bodyTexture;
        node.material.roughness = 1.7;
        node.material.roughnessMap = bodyRoughnessTexture;
        node.material.normalMap = bodyNormalTexture;
        node.material.normalScale = new Vector2(0.6, 0.6);

        morphTargetDictionaryBody = node.morphTargetDictionary;

        node.material.envMapIntensity = 0.8;

      }

      if (node.name.includes("Eyes")) {
        node.material = new MeshStandardMaterial();
        node.material.map = eyesTexture;
        // node.material.shininess = 100;
        node.material.roughness = 0.1;
        node.material.envMapIntensity = 0.5;

      }

      if (node.name.includes("Brows")) {
        node.material = new LineBasicMaterial({ color: 0x000000 });
        node.material.linewidth = 1;
        node.material.opacity = 0.5;
        node.material.transparent = true;
        node.visible = false;
      }

      if (node.name.includes("Teeth")) {

        node.receiveShadow = true;
        node.castShadow = true;
        node.material = new MeshStandardMaterial();
        node.material.roughness = 0.1;
        node.material.map = teethTexture;
        node.material.normalMap = teethNormalTexture;

        node.material.envMapIntensity = 0.9;
      }

      if (node.name.includes("Hair")) {
        node.material = new MeshStandardMaterial();
        node.material.map = hairTexture;
        node.material.alphaMap = hairAlphaTexture;
        node.material.normalMap = hairNormalTexture;
        node.material.roughnessMap = hairRoughnessTexture;

        node.material.transparent = true;
        node.material.depthWrite = false;
        node.material.side = 2;
        node.material.color.setHex(0x000000);

        node.material.envMapIntensity = 0.6;
      }

      if (node.name.includes("TSHIRT")) {
        node.material = new MeshStandardMaterial();

        node.material.map = tshirtDiffuseTexture;
        node.material.roughnessMap = tshirtRoughnessTexture;
        node.material.normalMap = tshirtNormalTexture;
        node.material.color.setHex(0xffffff);
        node.material.envMapIntensity = 0.6;
      }

      if (node.name.includes("TeethLower")) {
        morphTargetDictionaryLowerTeeth = node.morphTargetDictionary;
      }
    }

  });

  const [clips, setClips] = useState([]);
  const mixer = useMemo(() => new THREE.AnimationMixer(gltf.scene), []);

  useEffect(() => {

    if (speak === false)
      return;

    console.log('hhhh', filename);
    if (BlendData != {}) {
      let newClips = [createAnimation(BlendData, morphTargetDictionaryBody, 'HG_Body'),
      createAnimation(BlendData, morphTargetDictionaryLowerTeeth, 'HG_TeethLower')];

      filename = host + filename;

      setClips(newClips);
      setAudioSource(filename);
      // setSpeak(false)
    }
  }, [speak]);

  let idleFbx = useFBX('/idle.fbx');
  let { clips: idleClips } = useAnimations(idleFbx.animations);

  idleClips[0].tracks = _.filter(idleClips[0].tracks, track => {
    return track.name.includes("Head") || track.name.includes("Neck") || track.name.includes("Spine2");
  });

  idleClips[0].tracks = _.map(idleClips[0].tracks, track => {
    if (track.name.includes("Head")) {
      track.name = "head.quaternion";
    }

    if (track.name.includes("Neck")) {
      track.name = "neck.quaternion";
    }

    if (track.name.includes("Spine")) {
      track.name = "spine2.quaternion";
    }
    return track;

  });

  useEffect(() => {
    let idleClipAction = mixer.clipAction(idleClips[0]);
    idleClipAction.play();

    let blinkClip = createAnimation(blinkData, morphTargetDictionaryBody, 'HG_Body');
    let blinkAction = mixer.clipAction(blinkClip);
    blinkAction.play();
  }, []);


  // Play animation clips when available
  useEffect(() => {
    if (playing === false)
      return;

    const actions = _.map(clips, clip => {
      let clipAction = mixer.clipAction(clip);
      clipAction.setLoop(THREE.LoopOnce);
      clipAction.play();
      return clipAction
    });

    setClipActions(actions)
  }, [playing]);


  useFrame((state, delta) => {
    mixer.update(delta);
  });

  return (
    <group name="avatar">
      <primitive object={gltf.scene} dispose={null} />
    </group>
  );
}

const STYLES = {
  area: { position: 'absolute', bottom: '0', left: '0', zIndex: 500, display: 'flex' },
  speak: { padding: '5px', display: 'block', color: '#FFFFFF', background: '#222222', border: 'None', right: '5px' },
  stop: { padding: '10px', left: '10px', color: 'red', background: '#FFFFF', radious: '5px' },
  label: { color: '#777777', fontSize: '0.5em' }
}

function Chat() {
  const navigate = useNavigate();
  const [chats, setChats] = useState([]);
  const [quest, setQuest] = useState([]);
  const [text, setText] = useState("");
  const [msg, setMsg] = useState("");
  const [exct, setexct] = useState("");
  const [load, setLoad] = useState(false);
  const [blendData, setBlendData] = useState({});
  const [authenticated, setAuthenticated] = useState(false);
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [chatHistory, setChatHistory] = useState([]);
  const [messages, setMessages] = useState([]);
  const [chatId, setChatId] = useState();
  const [clipActions, setClipActions] = useState([]);
  const [playingId, setPlayingId] = useState(null); // To track which audio is playing
  const [isListening, setIsListening] = useState(false);
  const [langu, setLang] = useState("");
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const cred = JSON.parse(sessionStorage.getItem("cred"))
  const userId = cred.uid
  const useremail = cred.email
  const purpose = cred.purpose
  const token = sessionStorage.getItem('yariyan_gal');
  const language = sessionStorage.getItem('lang');
  const subscription = sessionStorage.getItem('subpurpose');
  const sanitizedSubscription = subscription.replace(/^\//, ''); // Remove the leading '/'
  console.log("sub : ", sanitizedSubscription);
  console.log("hey my uid is : ", userId);


  const getResposnse = async (msg) => {
    if (msg === '') {
      toast.error("Promt can't be empty.[In some browsers mic may not work]");
      return;
    }

    if (load === true || speak === true) {
      toast.error("Already generating response!");
      return;
    }
    setChats([]);
    setQuest([{ msg: msg, who: 'me' }]);

    setMsg("");
    setLoad(true);

    let localChatId = chatId;
    if (!chatId) {
      localChatId = uuidv4();
      console.log('cc : ', localChatId);
      setChatId(localChatId);
    }
    console.log("file nahi h generate call hua");
    const toastgen = toast.loading("Please wait...");
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ prompt: `${msg}`, cid: localChatId, user_id: `${userId}`, email: `${useremail}`, language: `${language}`, subscription: `${sanitizedSubscription}` })
    };

    // const start = new Date();
    try {
      const response = await fetch('https://vaani.aionasset.in/generate', requestOptions);
      const result = await response.json();
      // const timeTaken = (new Date()) - start;
      // console.log('dcjskdc : ',response);
      console.log('resss: ', result);
      const text = result[0].response;

      console.log('res-gen : ', text);
      setBlendData(result[1])
      setSpeak(true);
      setText(text);
      toast.update(toastgen, { render: "Success!", type: "success", isLoading: false, autoClose: 500 });
      // setexct(timeTaken / 1000);
    } catch (error) {
      console.error('Error generating response:', error);
      toast.update(toastgen, { render: "Failed to generate response!", type: "error", isLoading: false, autoClose: 2000 });
      toast.error("Failed to generate response.");
    }
    setLoad(false);
  }

  useEffect(() => {
    document.querySelector('.chat-box').scrollTop = document.querySelector('.chat-box').scrollHeight;
  }, [chats])

  const audioPlayer = useRef();

  const [speak, setSpeak] = useState(false);
  const [audioSource, setAudioSource] = useState(null);
  const [playing, setPlaying] = useState(false);

  // End of play
  function playerEnded(e) {
    try {
      setAudioSource(null);
      setSpeak(false);
      setPlaying(false);
      fetchChatHistoryByUserId();
      fetchMessagesByChatOrUserId(chatId);
    } catch (error) {
      console.log("Error occured : ", e);
    }
  }

  // Player is read
  function playerReady(e) {
    try {
      console.log('hello playerReady function call.')
      audioPlayer.current.audioEl.current.play();
      setPlaying(true);
      setChats([{ msg: text, who: 'bot', exct: exct }]);
    } catch (e) {
      console.log("Something else : ", e);
    }
  }

  useEffect(() => {
    // Fetch chat history when userId is available
    if (userId) {
      fetchChatHistoryByUserId();
    }
  }, [userId]);


  const fetchChatHistoryByUserId = async () => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`  // Add the token in the Authorization header
        },
        body: JSON.stringify({ user_id: `${userId}`, email: `${useremail}`, subscription: `${sanitizedSubscription}` })
      };
      const response = await fetch('https://vaani.aionasset.in/fetchdata', requestOptions);

      if (!response.ok) {
        throw new Error('Failed to fetch chat history');
      }

      const data = await response.json();
      console.log('all chat with uid : ', data);
      setChatHistory(data);
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
  };

  // API function to fetch messages by chat ID or user ID
  const fetchMessagesByChatOrUserId = async (cid) => {
    try {
      setQuest([]);
      setChats([]);
      setChatId(cid);
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ user_id: `${userId}`, email: `${useremail}`, chat_id: cid })
      };
      const response = await fetch('https://vaani.aionasset.in/fetchmessage', requestOptions);

      if (!response.ok) {
        throw new Error('Failed to fetch messages');
      }

      const mess = await response.json();
      setMessages(mess);
      console.log('all meassage with cid : ', mess)
    } catch (error) {
      console.error('Error fetching messages:', error);
      return [];
    }
  };

  const odiyaTranslate = async (audioBlob) => {
    console.log("Calling Bhashini API...");
    const audioBase64 = await convertBlobToBase64(audioBlob);

    const url = "https://dhruva-api.bhashini.gov.in/services/inference/pipeline"; // Bhashini API endpoint
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': '6MPVGIKTkGYAgvfLCEu5DQ3xTzSS_iGK6A7BSNSYl8fkquoVTspInRSPC6bofxe2',
    };

    const payload = JSON.stringify({
      "pipelineTasks": [
        {
          "taskType": "asr",
          "config": {
            "language": {
              "sourceLanguage": "or" // Odia language code
            },
            "serviceId": "ai4bharat/conformer-multilingual-indo_aryan-gpu--t4",
            "audioFormat": "webm", // Change to webm format
            "samplingRate": 16000
          }
        }
      ],
      "inputData": {
        "audio": [
          {
            "audioContent": audioBase64 // Base64 encoded audio
          }
        ]
      }
    });

    try {
      const response = await axios.post(url, payload, { headers });
      if (response.data) {
        console.log("bhasini ended : ")
        const translatedText = response.data.pipelineResponse[0].output[0].source;
        console.log("ttt : ", translatedText)
        setMsg(translatedText);
      }
    } catch (error) {
      console.error('Error translating audio:', error.response ? error.response.data : error);
    }
  };

  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]); // Return only the base64 part
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };


  const { transcript, browserSupportsSpeechRecognition } = useSpeechRecognition();
  const silenceTimeoutRef = useRef(null); // To keep track of the timeout

  useEffect(() => {
    if (transcript) {
      console.log('Transcript updated: ', transcript);
      setMsg(transcript); // Append the new transcript to the existing message
      setIsListening(false);
    } else {
      console.log('Transcript is empty or not updating.');
    }
  }, [transcript]);

  const startOtherListening = () => {
    try {
      if (browserSupportsSpeechRecognition) {
        const langcod = language === 'en' ? 'en-US' : `${language}-IN`;
        console.log('Language code: ', langcod);

        SpeechRecognition.startListening({ language: langcod })
          .then(() => {
            console.log("Listening started...");
          })
          .catch((err) => {
            console.error("Error starting listening: ", err);
          });
      } else {
        console.error("Voice recognition not supported by the browser.");
      }
    } catch (e) {
      console.log("Error occurred: ", e);
    }
  };


  const startListening = async () => {
    console.log("hello languag is  : ", language);
    audioChunksRef.current = [];

    // Request access to the microphone
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);

      mediaRecorder.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        await odiyaTranslate(audioBlob);
      };

      mediaRecorder.start();
      mediaRecorderRef.current = mediaRecorder;
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopListening = () => {
    if (mediaRecorderRef.current) {
      setIsListening(false);
      console.log("stop call hua");
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
    }
    clearTimeout(silenceTimeoutRef.current);
    //   console.log("other stop call hua");
    //   SpeechRecognition.stopListening();
    // }
  };

  useEffect(() => {
    if (isListening) {
      console.log("lang : ", language);
      if (language === 'or') {
        console.log("udia call hua");
        startListening();
        silenceTimeoutRef.current = setTimeout(() => {
          console.log("Auto-stopping after 10 seconds of inactivity.");
          stopListening(); // Automatically stop listening after 5 seconds
        }, 10000);
      }
      else {
        console.log("other language call hua ")
        startOtherListening();
      }
    }
  }, [isListening]);


  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("authenticated");
    if (loggedInUser === "true") {
      setAuthenticated(true);
    } else if (cred.length === 0) {
      console.log("hi elseif");
      setAuthenticated(false);
      setPlayingId(null);
      navigate("/render");
    } else {
      console.log("else part generate");
      setAuthenticated(false);
      setPlayingId(null);
      navigate("/render", { replace: true });
    }
  }, [authenticated, navigate]);

  const handleNavigation = async (e) => {
    e.preventDefault();
    setPlayingId(null);
    sessionStorage.setItem("subpurpose", e.target.value);
    navigate('/' + e.target.value);
  }

  const handleLogout = () => {
    try {
      sessionStorage.setItem("authenticated", false);
      setAuthenticated(false);
      setPlayingId(null);
      navigate("/render");
    } catch (e) {
      console.log("Error occured : ", e);
    }
  }

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };


  const speakStop = async () => {
    try {

      console.log('Stop button clicked');

      audioPlayer.current.audioEl.current.pause();
      setPlaying(false);
      setAudioSource(null);
      setSpeak(false);
      // if (browserSupportsSpeechRecognition && SpeechRecognition.getRecognition()) {
      //   SpeechRecognition.stopListening();
      // }
      clipActions.forEach(action => {
        action.stop();  // Stop each action
      });

      setClipActions([]);  // Clear the actions
      fetchChatHistoryByUserId();
      fetchMessagesByChatOrUserId(chatId);
    } catch (e) {
      console.log("Error occured :", e);
    }

  };

  function autoResize(textarea) {
    try {
      textarea.style.height = 'auto'; // Reset the height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set it to the scroll height
    } catch (e) {
      console.log("Error occured : ", e);
    }
  }

  console.log('chats : ', chats);
  console.log('skcvv : ', chatHistory)

  // const sortedChatHistory = [...chatHistory].sort((a, b) => new Date(b.res_time) - new Date(a.res_time));
  const groupChatsByCid = (chats) => {
    try {
      const groupedChats = {};
      chats.forEach(chat => {
        if (!groupedChats[chat.cid]) {
          groupedChats[chat.cid] = [];
        }
        groupedChats[chat.cid].push(chat);
      });
      return groupedChats;
    } catch (e) {
      console.log("Error occured : ", e);
    }
  };

  // Filter chats based on time ranges
  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);

  const todayChats = chatHistory.filter(chat => {
    const chatDate = new Date(chat.res_time);
    return chatDate.toDateString() === today.toDateString();
  });
  // Sort by res_time in descending order
  todayChats.sort((a, b) => new Date(b.res_time) - new Date(a.res_time));

  const sevenDaysChats = chatHistory.filter(chat => {
    const chatDate = new Date(chat.res_time);
    return chatDate >= sevenDaysAgo && chatDate.toDateString() !== today.toDateString();
  });

  const olderChats = chatHistory.filter(chat => {
    const chatDate = new Date(chat.res_time);
    return chatDate < sevenDaysAgo;
  });

  // Group chats by cid and sort within the group
  const groupedTodayChats = groupChatsByCid(todayChats);
  const groupedSevenDaysChats = groupChatsByCid(sevenDaysChats);
  const groupedOlderChats = groupChatsByCid(olderChats);

  // Function to sort groups by the latest chat's res_time
  const sortByLatestChatTime = (groupedChats) => {
    return Object.keys(groupedChats)
      .map(cid => ({ cid, chats: groupedChats[cid] }))
      .sort((a, b) => new Date(b.chats[0].res_time) - new Date(a.chats[0].res_time));
  };

  // Sort grouped chats
  const sortedTodayChats = sortByLatestChatTime(groupedTodayChats);
  const sortedSevenDaysChats = sortByLatestChatTime(groupedSevenDaysChats);
  const sortedOlderChats = sortByLatestChatTime(groupedOlderChats);

  const [dropdownVisible, setDropdownVisible] = useState(null);

  // Toggle dropdown visibility
  const toggleDropdown = (cid) => {
    setDropdownVisible(dropdownVisible === cid ? null : cid);
  };

  const handleLangChange = async (e) => {
    e.preventDefault();
    setLang(e.target.value);
    sessionStorage.setItem("lang", e.target.value);
  }

  const handleChatAction = (action, chatId) => {
    try {

      if (action === "delete") {
        // Call your delete chat function
        deleteChat(chatId);
        // console.log("delete chat")
      }
      // } else if (action === "rename") {
      //   // Prompt for a new name and call your rename chat function
      //   const newName = prompt("Enter new chat name:");
      //   if (newName) {
      //     // renameChat(chatId, newName);
      //     console.log('rename chat');
      //   }
      // }
      // Hide the dropdown after action
      setDropdownVisible(null);
    } catch (e) {
      console.log("Error occured : ", e);
    }
  };


  const deleteChat = async (cid) => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`  // Add the token in the Authorization header
        },
        body: JSON.stringify({ user_id: `${userId}`, chat_id: cid, email: `${useremail}` })
      };
      const response = await fetch('https://vaani.aionasset.in/deleteChat', requestOptions);

      if (!response.ok) {
        throw new Error('Failed to fetch messages');
      }

      const mess = await response.json();
      console.log('get res : ', mess.response);
      toast.error(mess.response);
      setTimeout(() => {
        // window.location.reload();
        setQuest([]);
        setChats([]);
        setMessages([]);
        fetchChatHistoryByUserId();
      }, 2000); // 2000 milliseconds = 2 seconds
    } catch (error) {
      console.error('Error fetching messages:', error);
      return [];
    }
  };

  const handleNewChat = () => {
    const newChatId = uuidv4();
    console.log('new chat : ', newChatId);
    setChatId(newChatId);
    setQuest([]);
    setChats([]);
    setMessages([]);
  }

  const [voices, setVoices] = useState([]); // To store available voices

  useEffect(() => {
    // Load available voices
    const loadVoices = () => {
      const availableVoices = window.speechSynthesis.getVoices();
      setVoices(availableVoices);
    };

    // Load voices when available
    loadVoices();

    // SpeechSynthesis API voice change event listener
    window.speechSynthesis.onvoiceschanged = loadVoices;
  }, []);

  const getFemaleVoice = () => {
    try {
      return voices.find(voice => voice.name.toLowerCase().includes('female'));
    } catch (e) {
      console.log("something else : ", e);
    }
  };

  const [copiedId, setCopiedId] = useState(null);

  const handleCopy = (text, id) => {
    try {
      navigator.clipboard.writeText(text).then(() => {
        setCopiedId(id);
        setTimeout(() => setCopiedId(null), 2000); // Reset after 2 seconds
      });
    } catch (e) {
      console.log("Error occured : ", e);
    }
  };

  const handlePlayPauseAudio = (text, id) => {
    try {

      if (playingId === id) {
        // If the audio is already playing, pause it
        window.speechSynthesis.cancel(); // Stop speech synthesis
        setPlayingId(null); // Reset playing state
      } else {
        // If no au
        const cleanText = text.replace(/[*#]+/g, '');
        const utterance = new SpeechSynthesisUtterance(cleanText);
        const femaleVoice = getFemaleVoice();

        if (femaleVoice) {
          utterance.voice = femaleVoice;
        }

        utterance.onend = () => {
          setPlayingId(null); // Reset when audio finishes
        };
        window.speechSynthesis.speak(utterance);
        setPlayingId(id); // Set the current message as playing
      }
    } catch (e) {
      console.log("Something else : ", e);
    }
  };

  const formatResponse = (response) => {
    return response.replace(/([0-9]+)\.\s/g, '\n$1. ');
  };


  return (
    <div className="flex h-screen bg-gray-100">
      <ToastContainer
        position="top-left"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      {/* Sidebar */}
      <div className={`${isSidebarVisible ? 'w-64' : 'w-16'} bg-gray-800 text-white transition-all duration-300 ease-in-out`}>
        <div className="p-4 flex justify-between items-center">
          <button onClick={toggleSidebar} className="text-white">
            <FontAwesomeIcon icon={isSidebarVisible ? faTimes : faBars} />
          </button>
          {isSidebarVisible && (
            <button onClick={handleLogout} className="text-sm">
              <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
              Logout
            </button>
          )}
        </div>
        {isSidebarVisible && (
          <div className="p-4">
            <button
              onClick={handleNewChat}
              className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mb-4 flex items-center justify-center"
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              New Chat
            </button>
            <select id="path"
              name="path"
              className="mb-4 w-full p-2 bg-gray-700 text-white rounded"
              onChange={handleNavigation}>
              <option value={subscription}>Navigate To</option>
              <option value="img_analysis">Safety Work Zone Analysis</option>
              <option value="doc_upload">Document Analysis</option>
              {/* <option value="train">Trainig site</option> */}
            </select>
            <select id="language"
              name="language"
              className="mb-4 w-full p-2 bg-gray-700 text-white rounded"
              onClick={handleLangChange}>
              <option value={language}>Language</option>
              <option value="hi">Hindi</option>
              <option value="en">English</option>
              <option value="bn">Bengali</option>
              <option value="te">Telugu</option>
              <option value="mr">Marathi</option>
              <option value="ta">Tamil</option>
              <option value="gu">Gujarati</option>
              <option value="kn">Kannada</option>
              <option value="or">Odia</option>
            </select>

            {sortedTodayChats.length > 0 && (
              <>
                <h4 className="font-bold">Today:</h4>
                {sortedTodayChats.map(({ cid, chats }) => (
                  <div key={cid} className="chat-item relative">
                    <div className="chat-message-container">
                      <p className="chat-heading" onClick={() => fetchMessagesByChatOrUserId(cid)}>
                        {chats[chats.length - 1].question}
                      </p>
                      <span className="dots-icon" onClick={() => toggleDropdown(cid)}>&#x2026;</span>
                    </div>
                    {dropdownVisible === cid && (
                      <div className="dropdown-menu">
                        <ul className="py-1">
                          <li onClick={() => handleChatAction("delete", cid)}>Delete</li>
                        </ul>
                      </div>
                    )}
                  </div>
                ))}
              </>
            )}

            {/* Repeat similar structure for sevenDaysChats and olderChats */}
            {sortedSevenDaysChats.length > 0 && (
              <>
                <h4 class="font-bold">Previous 7 Day</h4>
                {sortedSevenDaysChats.map(({ cid, chats }) => (
                  <div key={cid} className="chat-item" style={{ position: "relative" }}>
                    {/* Flex container for message and three-dot icon */}
                    <div className="chat-message-container">
                      {/* Chat message heading */}
                      <p className="chat-heading" onClick={() => fetchMessagesByChatOrUserId(cid)}>
                        {chats[0].question}
                      </p>

                      {/* Three dots icon for dropdown */}
                      <span className="dots-icon" onClick={() => toggleDropdown(cid)}>
                        &#x2026;
                      </span>
                    </div>

                    {/* Dropdown menu */}
                    {dropdownVisible === cid && (
                      <div className="dropdown-menu">
                        <ul>
                          {/* <li onClick={() => handleChatAction("rename", cid)}>Rename</li> */}
                          <li onClick={() => handleChatAction("delete", cid)}>Delete</li>
                        </ul>
                      </div>
                    )}
                  </div>
                ))}
              </>
            )}

            {sortedOlderChats.length > 0 && (
              <>
                <h4 class="font-bold">Older Chat</h4>
                {sortedOlderChats.map(({ cid, chats }) => (
                  <div key={cid} className="chat-item" style={{ position: "relative" }}>
                    {/* Flex container for message and three-dot icon */}
                    <div className="chat-message-container">
                      {/* Chat message heading */}
                      <p className="chat-heading" onClick={() => fetchMessagesByChatOrUserId(cid)}>
                        {chats[0].question}
                      </p>

                      {/* Three dots icon for dropdown */}
                      <span className="dots-icon" onClick={() => toggleDropdown(cid)}>
                        &#x2026;
                      </span>
                    </div>

                    {/* Dropdown menu */}
                    {dropdownVisible === cid && (
                      <div className="dropdown-menu">
                        <ul>
                          {/* <li onClick={() => handleChatAction("rename", cid)}>Rename</li> */}
                          <li onClick={() => handleChatAction("delete", cid)}>Delete</li>
                        </ul>
                      </div>
                    )}
                  </div>
                ))}
              </>
            )}
          </div>
        )}
      </div>

      {/* Main content */}
      <div className="flex-1 flex-col">
        <div style={STYLES.area}>
          <button style={STYLES.speak}>
            {speak || load ? 'Thinking...' : 'Type message.'}
          </button>
          {speak && (
            <button style={STYLES.stop} onClick={speakStop}>
              Stop
            </button>
          )}
        </div>

        <div className="chat-div">
          <div className="chat-box">

            {/* Render chat history from the API */}
            <p className="bot">Vaani : How can I assist you today?</p>
            {messages.map((history, index) => (
              <div key={index} style={{ position: 'relative', marginBottom: '20px' }}>
                <p className="me">{history.question}</p>
                <p className="bot" key={index}>

                  {/* Flex container for the icons */}
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>

                    {/* Audio Play Icon (Always visible) */}
                    <span
                      className="audio-icon"
                      onClick={() => handlePlayPauseAudio(history.response, index)}
                      style={{
                        cursor: 'pointer',
                        fontSize: '18px', // Adjust icon size
                        marginLeft: '10px', // Add space between icons
                      }}
                    >
                      {playingId === index ? (
                        <FontAwesomeIcon icon={faPause} /> // Show Pause icon when playing
                      ) : (
                        <FontAwesomeIcon icon={faPlay} /> // Show Play icon when paused
                      )}
                      <span className="tooltip-text">Play/Pause</span>
                    </span>
                    {/* Copy Icon (Always visible) */}
                    <span
                      className="copy-icon"
                      onClick={() => handleCopy(history.response, index)}
                      style={{
                        cursor: 'pointer',
                        fontSize: '18px', // Adjust icon size
                      }}
                    >
                      {copiedId === index ? (
                        <FontAwesomeIcon icon={faCheck} />
                      ) : (
                        <FontAwesomeIcon icon={faCopy} />
                      )}
                      <span className="tooltip-text">Copy</span>
                    </span>
                  </div>
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {formatResponse(history.response)}
                  </ReactMarkdown>
                </p>
              </div>
            ))}
            {quest.length > 0 && (
              quest.map((chat) => {
                return <p className="me">{chat.msg}</p>;
              })
            )}
            {chats.length > 0 && (
              chats.map((chat) => {
                return <p className="bot">
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {/* {chat.msg} */}
                    {formatResponse(chat.msg)}
                  </ReactMarkdown></p>
              })
            )}

            {(load == true || speak) && !playing ? (
              <p style={{ padding: "5px", display: "flex", alignItems: "center" }}>
                <lottie-player
                  src="https://lottie.host/8891318b-7fd9-471d-a9f4-e1358fd65cd6/EQt3MHyLWk.json"
                  style={{ width: "50px", height: "50px" }}
                  loop
                  autoplay
                  speed="1.4"
                  direction="1"
                  mode="normal"
                ></lottie-player>
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="msg-box">
            <button
              className="msgbtn"
              id="mic"
              onTouchStart={() => setIsListening(true)}
              onMouseDown={() => setIsListening(true)}
              onTouchEnd={() => setIsListening(true)}
              onMouseUp={() => setIsListening(true)}
            >
              <span className="tooltip-text">Tap to talk</span>
              <img src="./images/icons/mic.png" alt="mic" unselectable="on"></img>
            </button>
            <textarea
              className="text-area"
              value={msg}
              onChange={(e) => {
                setMsg(e.target.value);
                autoResize(e.target);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault(); // Prevents adding a new line
                  getResposnse(msg);
                }
              }}
              placeholder="Say Hello!"
            />
            <button className="msgbtn" id="send" onClick={() => getResposnse(msg)}>
              <img src="./images/icons/send.png" alt="mic"></img>
            </button>
          </div>
        </div>
        <ReactAudioPlayer
          src={audioSource}
          ref={audioPlayer}
          onEnded={playerEnded}
          onCanPlayThrough={playerReady}
        />
        <Canvas
          className="flex-1 flex-col"
          dpr={2}
          onCreated={(ctx) => {
            ctx.gl.physicallyCorrectLights = true;
          }}
        >
          <OrthographicCamera makeDefault zoom={1300} position={[0, 1.6, 1]} />
          <Suspense fallback={null}>
            <Environment background={false} files="/images/photo_studio_loft_hall_1k.hdr" />
          </Suspense>
          <Suspense fallback={null}>
            <Bg
              purpose={purpose} />
          </Suspense>
          <Suspense fallback={null}>
            <Avatar
              avatar_url="/vani1.glb"
              speak={speak}
              setSpeak={setSpeak}
              text={text}
              setAudioSource={setAudioSource}
              playing={playing}
              blendData={blendData}
              setClipActions={setClipActions}
            />
          </Suspense>
        </Canvas>
        <Loader dataInterpolation={(p) => `Loading... please wait`} />
      </div>
    </div >
  );
}

function Bg(purpose) {
  console.log('skcsb', purpose);
  const textureConstruction = useTexture('/images/background.jpg');
  const textureMaintenance = useTexture('/images/maintenance.jpg');

  let texture;
  if (purpose.purpose === 'construction') {
    texture = textureConstruction;
  } else {
    texture = textureMaintenance;
  }

  return (
    <mesh position={[0, 1.5, -4]} scale={[1.2, 1.2, 1.2]}>
      <planeGeometry args={[]} />
      <meshBasicMaterial map={texture} />
    </mesh>
  );
}
export default Chat;

import React, { useState } from "react";
import Sidebar from "./components/Sidebar";
import MainContent from "./MainContent";
import AssistantPanel from "./AssistantPanel";
import "./comp.css";

function Advance_tool() {
    const [isAssistantVisible, setIsAssistantVisible] = useState(true);

    // Toggle assistant panel visibility
    const toggleAssistant = () => {
        setIsAssistantVisible(!isAssistantVisible);
    };

    return (
        <div className="app-container">
            <Sidebar />
            {/* Adjust width based on Assistant Panel visibility */}
            <div className={`main-container ${isAssistantVisible ? "with-assistant" : "without-assistant"}`}>
                <MainContent />
            </div>
            {/* Only render AssistantPanel if visible */}
            {isAssistantVisible && <AssistantPanel onClose={toggleAssistant} />}
            {/* Show three-dot icon if AssistantPanel is hidden */}
            {!isAssistantVisible && (
                <div className="open-icon" onClick={toggleAssistant}>
                    <i className="fas fa-ellipsis-v"></i> {/* Three-dot icon */}
                </div>
            )}
        </div>
    );
}

export default Advance_tool;
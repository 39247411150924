import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faBars, faTimes, faPlus, faMicrophone, faPlay, faPause, faCheck, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { Button, Card, CardHeader, CardContent } from '@mui/material';
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { saveAs } from 'file-saver'; // For downloading the image
import { FaDownload } from 'react-icons/fa';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // Import the Lightbox styles
import './doc.css';

const DocAnalysis = () => {
    const navigate = useNavigate();
    const [authenticated, setAuthenticated] = useState(false);
    const [isSidebarVisible, setSidebarVisible] = useState(true);
    const [chatHistory, setChatHistory] = useState([]);
    const [analysis, setAnalysis] = useState('');
    const [fileRecord, setFileRecord] = useState([]);
    const [chatId, setChatId] = useState();
    const [chats, setChats] = useState([]);
    const [quest, setQuest] = useState([]);
    const [messages, setMessages] = useState([]);
    const [image, setImage] = useState('');
    const [msg, setMsg] = useState('');
    const [isListening, setIsListening] = useState(false);
    const mediaRecorderRef = useRef(null);
    const [playingId, setPlayingId] = useState(null); // To track which audio is playing
    const audioChunksRef = useRef([]);
    const [isOpen, setIsOpen] = useState(false);

    const cred = JSON.parse(sessionStorage.getItem("cred"));
    const userId = cred.uid;
    const useremail = cred.email;
    const token = sessionStorage.getItem('yariyan_gal');
    let language = sessionStorage.getItem('lang');
    const subscription = sessionStorage.getItem('subpurpose');
    const sanitizedSubscription = subscription.replace(/^\//, ''); // Remove the leading '/'
    console.log("sub : ", sanitizedSubscription);

    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("authenticated");
        if (loggedInUser === "true") {
            setAuthenticated(true);
        } else {
            setPlayingId(null);
            setAuthenticated(false);
            navigate("/render", { replace: true });
        }
    }, [authenticated, navigate]);

    useEffect(() => {
        if (userId) {
            fetchChatHistoryByUserId();
        }
    }, [userId]);



    const fetchChatHistoryByUserId = async () => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ user_id: `${userId}`, email: `${useremail}`, subscription: `${sanitizedSubscription}` })
            };
            const response = await fetch('https://vaani.aionasset.in/fetchdata', requestOptions);

            if (!response.ok) {
                throw new Error('Failed to fetch chat history');
            }

            const data = await response.json();
            setChatHistory(data);
        } catch (error) {
            console.error('Error fetching chat history:', error);
        }
    };

    const fetchMessagesByChatOrUserId = async (cid) => {
        try {
            setQuest([]);
            setChats([]);
            setChatId(cid);
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ user_id: userId, email: useremail, chat_id: cid })
            };
            const response = await fetch('https://vaani.aionasset.in/fetchmessage', requestOptions);

            if (!response.ok) {
                throw new Error('Failed to fetch messages');
            }

            const mess = await response.json();
            setMessages(mess);
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    const handleLogout = () => {
        sessionStorage.setItem("authenticated", false);
        setPlayingId(null);
        setAuthenticated(false);
        navigate("/render");
    };

    const toggleSidebar = () => {
        setSidebarVisible(!isSidebarVisible);
    };

    const handleNewChat = () => {
        const newChatId = uuidv4();
        console.log('new chat : ', newChatId);
        setChatId(newChatId);
        setQuest([]);
        setChats([]);
        setMessages([]);
        setFileRecord([]);
        setAnalysis();
    };

    const handleLangChange = async (e) => {
        e.preventDefault();
        sessionStorage.setItem("lang", e.target.value);
    }

    language = sessionStorage.getItem('lang');

    const handleNavigation = async (e) => {
        e.preventDefault();
        setPlayingId(null);
        sessionStorage.setItem("subpurpose", e.target.value);
        navigate('/' + e.target.value);
    }

    const handleFileChange = async (e) => {
        try {

            console.log("Files selected");

            // Generate chatId if it does not exist
            let cid = chatId;
            if (!chatId) {
                cid = uuidv4();
                console.log('Generated chatId:', cid);
                setChatId(cid);
            }

            const selectedFiles = Array.from(e.target.files); // Get selected files as an array

            // Loop through each file and upload it one by one
            for (const file of selectedFiles) {
                const formData = new FormData();
                formData.append('file', file); // Append the current file
                formData.append('email', useremail); // Append email
                formData.append('cid', cid); // Append chat ID
                formData.append('uid', userId); // Append user ID

                console.log(`Uploading file: ${file.name}`);

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,  // Add Authorization header if required
                    },
                    body: formData,
                };

                console.log(`Uploading file: ${file.name}`);
                setAnalysis(file.name);
                // toast.info("pLease wait file is uploading...");
                const toastUpload = toast.loading("Please wait file is loading...");
                try {
                    const response = await fetch('https://vaani.aionasset.in/upload_files', requestOptions);

                    if (!response.ok) {
                        throw new Error(`Failed to upload file: ${file.name}`);
                    }

                    const result = await response.json();
                    console.log("Upload result for : ", result.file_id);
                    setFileRecord((prevFileRecord) => [...prevFileRecord, result]);
                    toast.update(toastUpload, { render: `Success! file: ${file.name}`, type: "success", isLoading: false, autoClose: 500 });

                } catch (error) {
                    console.error(`Error uploading file: ${file.name}`, error);
                    toast.update(toastUpload, { render: `Failed to upload file: ${file.name}`, type: "error", isLoading: false, autoClose: 2000 });

                }
            }
            console.log("All files have been uploaded");
        } catch (e) {
            console.log("Error occured : ", e);
        }
    };

    const odiyaTranslate = async (audioBlob) => {
        console.log("Calling Bhashini API...");
        const audioBase64 = await convertBlobToBase64(audioBlob);

        const url = "https://dhruva-api.bhashini.gov.in/services/inference/pipeline"; // Bhashini API endpoint
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': '6MPVGIKTkGYAgvfLCEu5DQ3xTzSS_iGK6A7BSNSYl8fkquoVTspInRSPC6bofxe2',
        };

        const payload = JSON.stringify({
            "pipelineTasks": [
                {
                    "taskType": "asr",
                    "config": {
                        "language": {
                            "sourceLanguage": "or" // Odia language code
                        },
                        "serviceId": "ai4bharat/conformer-multilingual-indo_aryan-gpu--t4",
                        "audioFormat": "webm", // Change to webm format
                        "samplingRate": 16000
                    }
                }
            ],
            "inputData": {
                "audio": [
                    {
                        "audioContent": audioBase64 // Base64 encoded audio
                    }
                ]
            }
        });

        try {
            const response = await axios.post(url, payload, { headers });
            if (response.data) {
                console.log("bhasini ended : ")
                const translatedText = response.data.pipelineResponse[0].output[0].source;
                console.log("ttt : ", translatedText)
                setMsg(translatedText);
            }
        } catch (error) {
            console.error('Error translating audio:', error.response ? error.response.data : error);
        }
    };

    const convertBlobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result.split(',')[1]); // Return only the base64 part
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    };

    const { transcript, browserSupportsSpeechRecognition } = useSpeechRecognition();
    const silenceTimeoutRef = useRef(null); // To keep track of the timeout



    useEffect(() => {
        if (transcript) {
            console.log('Transcript updated: ', transcript);
            setMsg(transcript); // Append the new transcript to the existing message
            setIsListening(false);
        } else {
            console.log('Transcript is empty or not updating.');
        }
    }, [transcript]);


    const startOtherListening = () => {
        try {
            if (browserSupportsSpeechRecognition) {
                const langcod = language === 'en' ? 'en-US' : `${language}-IN`;
                console.log('Language code: ', langcod);

                SpeechRecognition.startListening({ language: langcod })
                    .then(() => {
                        console.log("Listening started...");
                    })
                    .catch((err) => {
                        console.error("Error starting listening: ", err);
                    });
            } else {
                console.error("Voice recognition not supported by the browser.");
            }
        } catch (e) {
            console.log("Error occurred: ", e);
        }
    };



    const startListening = async () => {
        console.log("hello languag is  : ", language);
        audioChunksRef.current = [];

        // Request access to the microphone
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const mediaRecorder = new MediaRecorder(stream);

            mediaRecorder.ondataavailable = (event) => {
                audioChunksRef.current.push(event.data);
            };

            mediaRecorder.onstop = async () => {
                const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                await odiyaTranslate(audioBlob);
            };

            mediaRecorder.start();
            mediaRecorderRef.current = mediaRecorder;
        } catch (error) {
            console.error('Error accessing microphone:', error);
        }
    };

    const stopListening = () => {
        if (mediaRecorderRef.current) {
            setIsListening(false);
            console.log("stop call hua");
            mediaRecorderRef.current.stop();
            mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
        }
        clearTimeout(silenceTimeoutRef.current);
        //   console.log("other stop call hua");
        //   SpeechRecognition.stopListening();
        // }
    };

    useEffect(() => {
        if (isListening) {
            console.log("lang : ", language);
            if (language === 'or') {
                console.log("udia call hua");
                startListening();
                silenceTimeoutRef.current = setTimeout(() => {
                    console.log("Auto-stopping after 10 seconds of inactivity.");
                    stopListening(); // Automatically stop listening after 5 seconds
                }, 10000);
            }
            else {
                console.log("other language call hua ")
                startOtherListening();
            }
        }
    }, [isListening]);

    const upload_data = async (msg) => {
        try {
            setImage('');
            if (msg === '') {
                toast.error("Promt can't be empty.[In some browsers mic may not work]");
                return;
            }
            let cid = chatId;
            if (!chatId) {
                cid = uuidv4();
                setChatId(cid);
            }
            setQuest([{ question: msg, who: 'me' }]);

            const toastdata = toast.loading("Please wait...");
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ msg: msg, email: `${useremail}`, cid: cid, uid: `${userId}`, language: `${language}`, subscription: `${sanitizedSubscription}` })
            };
            try {
                console.log('ready for api');
                const response = await fetch('https://vaani.aionasset.in/upload', requestOptions);

                if (!response.ok) {
                    throw new Error('Failed to upload file');
                }
                const result = await response.json();
                console.log("hello-hi: ", result);
                const text = result[0].response;
                if (result[0].plot) {
                    setImage(result[0].plot);
                }
                console.log('res-gen : ', text);
                setChats([{ response: text, who: 'bot' }]);
                // setBlendData(result[1])
                // setSpeak(true);
                toast.update(toastdata, { render: "Success!", type: "success", isLoading: false, autoClose: 500 });
                fetchMessagesByChatOrUserId(cid);
                setMsg('')
            } catch (error) {
                console.error('Error uploading file:', error);
                toast.update(toastdata, { render: "Failed to generate response!", type: "error", isLoading: false, autoClose: 2000 });
            }
        } catch (e) {
            console.log("Error occured : ", e);
        }
    }


    const groupChatsByCid = (chats) => {
        const groupedChats = {};
        chats.forEach(chat => {
            if (!groupedChats[chat.cid]) {
                groupedChats[chat.cid] = [];
            }
            groupedChats[chat.cid].push(chat);
        });
        return groupedChats;
    };

    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);

    const todayChats = chatHistory.filter(chat => new Date(chat.res_time).toDateString() === today.toDateString());
    const sevenDaysChats = chatHistory.filter(chat => {
        const chatDate = new Date(chat.res_time);
        return chatDate >= sevenDaysAgo && chatDate.toDateString() !== today.toDateString();
    });
    const olderChats = chatHistory.filter(chat => new Date(chat.res_time) < sevenDaysAgo);

    todayChats.sort((a, b) => new Date(b.res_time) - new Date(a.res_time));

    const groupedTodayChats = groupChatsByCid(todayChats);
    const groupedSevenDaysChats = groupChatsByCid(sevenDaysChats);
    const groupedOlderChats = groupChatsByCid(olderChats);

    const sortByLatestChatTime = (groupedChats) => {
        return Object.keys(groupedChats)
            .map(cid => ({ cid, chats: groupedChats[cid] }))
            .sort((a, b) => new Date(b.chats[0].res_time) - new Date(a.chats[0].res_time));
    };

    const sortedTodayChats = sortByLatestChatTime(groupedTodayChats);
    const sortedSevenDaysChats = sortByLatestChatTime(groupedSevenDaysChats);
    const sortedOlderChats = sortByLatestChatTime(groupedOlderChats);

    const [dropdownVisible, setDropdownVisible] = useState(null);

    const toggleDropdown = (cid) => {
        setDropdownVisible(dropdownVisible === cid ? null : cid);
    };

    const handleChatAction = (action, chatId) => {
        if (action === "delete") {
            deleteChat(chatId);
        }
        setDropdownVisible(null);
    };

    const deleteChat = async (cid) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ user_id: userId, chat_id: cid, email: useremail })
            };
            const response = await fetch('https://vaani.aionasset.in/deleteChat', requestOptions);

            if (!response.ok) {
                throw new Error('Failed to delete chat');
            }

            const mess = await response.json();
            toast.error(mess.response);
            setTimeout(() => {
                setQuest([]);
                setChats([]);
                setMessages([]);
                fetchChatHistoryByUserId();
            }, 2000);
        } catch (error) {
            console.error('Error deleting chat:', error);
        }
    };

    function autoResize(textarea) {
        try {
            textarea.style.height = 'auto'; // Reset the height
            textarea.style.height = `${textarea.scrollHeight}px`; // Set it to the scroll height
        } catch (e) {
            console.log("Error occured : ", e);
        }
    }

    // const [voices, setVoices] = useState([]); // To store available voices

    // useEffect(() => {
    //     // Load available voices
    //     const loadVoices = () => {
    //         const availableVoices = window.speechSynthesis.getVoices();
    //         setVoices(availableVoices);
    //     };

    //     // Load voices when available
    //     loadVoices();

    //     // SpeechSynthesis API voice change event listener
    //     window.speechSynthesis.onvoiceschanged = loadVoices;
    // }, []);

    // const getFemaleVoice = () => {
    //     try {
    //         return voices.find(voice => voice.name.toLowerCase().includes('female'));
    //     } catch (e) {
    //         console.log("something else : ", e);
    //     }
    // };

    const [copiedId, setCopiedId] = useState(null);

    const handleCopy = (text, id) => {
        try {
            navigator.clipboard.writeText(text).then(() => {
                setCopiedId(id);
                setTimeout(() => setCopiedId(null), 2000); // Reset after 2 seconds
            });
        } catch (e) {
            console.log("Error occured : ", e);
        }
    };

    // const handlePlayPauseAudio = (text, id) => {
    //     try {

    //         if (playingId === id) {
    //             // If the audio is already playing, pause it
    //             window.speechSynthesis.cancel(); // Stop speech synthesis
    //             setPlayingId(null); // Reset playing state
    //         } else {
    //             // If no audio is playing, start playing
    //             const cleanText = text.replace(/[*#]+/g, '');
    //             const utterance = new SpeechSynthesisUtterance(cleanText);
    //             const femaleVoice = getFemaleVoice();

    //             if (femaleVoice) {
    //                 utterance.voice = femaleVoice;
    //             }

    //             utterance.onend = () => {
    //                 setPlayingId(null); // Reset when audio finishes
    //             };
    //             window.speechSynthesis.speak(utterance);
    //             setPlayingId(id); // Set the current message as playing
    //         }
    //     } catch (e) {
    //         console.log("Something else : ", e);
    //     }
    // };

    const formatResponse = (response) => {
        return response.replace(/([0-9]+)\.\s/g, '\n$1. ');
    };


    const handleDownload = (image) => {
        try {
            const uniqueImageName = `img_${uuidv4()}.jpg`; // Create a unique name using UUID
            saveAs(image, uniqueImageName); // Save the image with the unique name
        } catch (e) {
            console.log("Error occured : ", e);
        }
    };

    const handleOpenImage = (image) => {
        try {
            console.log('open function call hua : ', image);
            setImage(image);
            setIsOpen(true);
        } catch (e) {
            console.log("Error occured : ", e);
        }
    };

    console.log("qq: ", quest);

    return (
        <div className="flex h-screen bg-gray-100">
            <ToastContainer position="top-left" autoClose={4000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />

            {/* Sidebar */}
            <div className={`${isSidebarVisible ? 'w-64' : 'w-16'} bg-gray-800 text-white transition-all duration-300 ease-in-out overflow-y-auto`}>
                <div className="p-4 flex justify-between items-center">
                    <button onClick={toggleSidebar} className="text-white">
                        <FontAwesomeIcon icon={isSidebarVisible ? faTimes : faBars} />
                    </button>
                    {isSidebarVisible && (
                        <button onClick={handleLogout} className="text-sm">
                            <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                            Logout
                        </button>
                    )}
                </div>
                {isSidebarVisible && (
                    <div className="p-4">
                        <button
                            onClick={handleNewChat}
                            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mb-4 flex items-center justify-center"
                        >
                            <FontAwesomeIcon icon={faPlus} className="mr-2" />
                            New Chat
                        </button>
                        <select id="path"
                            name="path"
                            className="mb-4 w-full p-2 bg-gray-700 text-white rounded"
                            onChange={handleNavigation}>
                            <option value={subscription}>Navigate To</option>
                            <option value="chat">Question/Answer</option>
                            <option value="img_analysis">Safety Work Zone Analysis</option>
                            {/* <option value="train">Trainig site</option> */}
                        </select>
                        <select id="language"
                            name="language"
                            className="mb-4 w-full p-2 bg-gray-700 text-white rounded"
                            onClick={handleLangChange}>
                            <option value={language}>Language</option>
                            <option value="hi">Hindi</option>
                            <option value="en">English</option>
                            <option value="bn">Bengali</option>
                            <option value="te">Telugu</option>
                            <option value="mr">Marathi</option>
                            <option value="ta">Tamil</option>
                            <option value="gu">Gujarati</option>
                            <option value="kn">Kannada</option>
                            <option value="or">Odia</option>
                        </select>
                        {sortedTodayChats.length > 0 && (
                            <>
                                <h4 className="font-bold">Today</h4>
                                {sortedTodayChats.map(({ cid, chats }) => (
                                    <div key={cid} className="chat-item relative">
                                        <div className="chat-message-container">
                                            <p className="chat-heading" onClick={() => fetchMessagesByChatOrUserId(cid)}>
                                                {chats[chats.length - 1].question}
                                            </p>
                                            <span className="dots-icon" onClick={() => toggleDropdown(cid)}>&#x2026;</span>
                                        </div>
                                        {dropdownVisible === cid && (
                                            <div className="dropdown-menu">
                                                <ul className="py-1">
                                                    <li onClick={() => handleChatAction("delete", cid)}>Delete</li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </>
                        )}

                        {/* Repeat similar structure for sevenDaysChats and olderChats */}
                        {sortedSevenDaysChats.length > 0 && (
                            <>
                                <h4 class="font-bold">Previous 7 Day</h4>
                                {sortedSevenDaysChats.map(({ cid, chats }) => (
                                    <div key={cid} className="chat-item" style={{ position: "relative" }}>
                                        {/* Flex container for message and three-dot icon */}
                                        <div className="chat-message-container">
                                            {/* Chat message heading */}
                                            <p className="chat-heading" onClick={() => fetchMessagesByChatOrUserId(cid)}>
                                                {chats[0].question}
                                            </p>

                                            {/* Three dots icon for dropdown */}
                                            <span className="dots-icon" onClick={() => toggleDropdown(cid)}>
                                                &#x2026;
                                            </span>
                                        </div>

                                        {/* Dropdown menu */}
                                        {dropdownVisible === cid && (
                                            <div className="dropdown-menu">
                                                <ul>
                                                    {/* <li onClick={() => handleChatAction("rename", cid)}>Rename</li> */}
                                                    <li onClick={() => handleChatAction("delete", cid)}>Delete</li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </>
                        )}

                        {sortedOlderChats.length > 0 && (
                            <>
                                <h4 class="font-bold">Older Chat</h4>
                                {sortedOlderChats.map(({ cid, chats }) => (
                                    <div key={cid} className="chat-item" style={{ position: "relative" }}>
                                        {/* Flex container for message and three-dot icon */}
                                        <div className="chat-message-container">
                                            {/* Chat message heading */}
                                            <p className="chat-heading" onClick={() => fetchMessagesByChatOrUserId(cid)}>
                                                {chats[0].question}
                                            </p>

                                            {/* Three dots icon for dropdown */}
                                            <span className="dots-icon" onClick={() => toggleDropdown(cid)}>
                                                &#x2026;
                                            </span>
                                        </div>

                                        {/* Dropdown menu */}
                                        {dropdownVisible === cid && (
                                            <div className="dropdown-menu">
                                                <ul>
                                                    {/* <li onClick={() => handleChatAction("rename", cid)}>Rename</li> */}
                                                    <li onClick={() => handleChatAction("delete", cid)}>Delete</li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                )}
            </div>

            {/* Main content area */}
            <div className="flex-1 p-4">
                <h1 className="text-2xl font-bold mb-4">Document Analysis</h1>
                <div className="flex h-full">
                    {/* Document Analysis Section */}
                    <Card className="flex-1 mr-4">
                        <CardHeader>
                            <h2 className="text-xl font-bold">Document Analysis</h2>
                        </CardHeader>
                        <CardContent>
                            <div className="mb-4 flex items-center space-x-2"> {/* Flex with spacing */}
                                {/* Microphone button */}
                                <button
                                    className="msgbtn"
                                    id="mic"
                                    onTouchStart={() => setIsListening(true)}
                                    onMouseDown={() => setIsListening(true)}
                                    onTouchEnd={() => setIsListening(true)}
                                    onMouseUp={() => setIsListening(true)}
                                >
                                    <span className="tooltip-text">Tap to talk</span>
                                    <FontAwesomeIcon icon={faMicrophone} size="2x" />
                                </button>

                                {/* Textarea */}
                                <textarea
                                    className="text-area flex-1" // Make textarea take available space
                                    value={msg}
                                    onChange={(e) => {
                                        setMsg(e.target.value);
                                        autoResize(e.target);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter" && !e.shiftKey) {
                                            e.preventDefault(); // Prevents adding a new line
                                            upload_data(msg);
                                        }
                                    }}
                                    placeholder="Say Hello!"
                                />

                                {/* File input with custom icon */}
                                <label htmlFor="file-input" className="cursor-pointer">
                                    <FontAwesomeIcon icon={faPaperclip} size="2x" /> {/* Icon for file upload */}
                                </label>
                                <input
                                    type="file"
                                    id="file-input"
                                    className="hidden" // Hide default input style
                                    onChange={handleFileChange}
                                    multiple // Allow multiple files
                                />

                                {/* Ask button */}
                                <Button onClick={() => upload_data(msg)}>Ask</Button>
                            </div>

                            {/* Scrollbar for analysis results */}

                            <div className="p-0 bg-white rounded-md overflow-auto font-bold ml-3 mr-2">
                                {analysis}
                            </div>
                        </CardContent>

                    </Card>

                    {/* Question & Answer Section */}
                    <Card className="flex-1">
                        <CardContent>
                            <div className="chat-doc">
                                <div className="chat-anal">

                                    {/* Render chat history from the API */}
                                    <p className="bot">Vaani : How can I assist you today?</p>
                                    {messages.map((history, index) => (
                                        <div key={index} style={{ position: 'relative', marginBottom: '20px' }}>
                                            <p className="me">{history.question}</p>
                                            <p className="bot" key={index}>

                                                {/* Flex container for the icons */}
                                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                    {history.image && (
                                                        <FaDownload
                                                            onClick={() => handleDownload(history.image)} // Call handleDownload when icon is clicked
                                                            style={{ cursor: 'pointer', fontSize: '18px', marginLeft: '10px' }} // Add custom styles
                                                        />
                                                    )}
                                                    {/* Audio Play Icon (Always visible) */}
                                                    {/* <span
                                                        className="audio-icon"
                                                        onClick={() => handlePlayPauseAudio(history.response, index)}
                                                        style={{
                                                            cursor: 'pointer',
                                                            fontSize: '18px', // Adjust icon size
                                                            marginLeft: '10px', // Add space between icons
                                                        }}
                                                    >
                                                        {playingId === index ? (
                                                            <FontAwesomeIcon icon={faPause} /> // Show Pause icon when playing
                                                        ) : (
                                                            <FontAwesomeIcon icon={faPlay} /> // Show Play icon when paused
                                                        )}
                                                        <span className="tooltip-text">Play/Pause</span>
                                                    </span> */}
                                                    {/* Copy Icon (Always visible) */}

                                                    <span
                                                        className="copy-icon"
                                                        onClick={() => handleCopy(history.response, index)}
                                                        style={{
                                                            cursor: 'pointer',
                                                            fontSize: '18px', // Adjust icon size
                                                        }}
                                                    >
                                                        {copiedId === index ? (
                                                            <FontAwesomeIcon icon={faCheck} />
                                                        ) : (
                                                            <FontAwesomeIcon icon={faCopy} />
                                                        )}
                                                        <span className="tooltip-text">Copy</span>
                                                    </span>
                                                </div>
                                                {/* Conditionally show image if present */}
                                                {history.image && (
                                                    <div onClick={() => handleOpenImage(history.image)}>
                                                        <img
                                                            src={history.image}
                                                            alt=""
                                                            style={{ maxWidth: '100%', cursor: 'pointer' }}
                                                        />
                                                    </div>
                                                )}
                                                <div className="text-base leading-relaxed">
                                                    {/* Assume chat.msg contains the structured content */}
                                                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                                        {formatResponse(history.response)}
                                                    </ReactMarkdown>
                                                </div>
                                                {/* Lightbox to open full image view */}
                                                {isOpen && (
                                                    <Lightbox
                                                        mainSrc={image}
                                                        onCloseRequest={() => setIsOpen(false)}
                                                    />
                                                )}
                                            </p>
                                        </div>
                                    ))}
                                    {quest.length > 0 && (
                                        quest.map((chat) => {
                                            return <p className="me">{chat.question}</p>;
                                        })
                                    )}
                                    {chats.length > 0 && (
                                        chats.map((chat, index) => {
                                            return (
                                                <div key={index} className="bot bg-gray-100 p-4 rounded-lg my-2">
                                                    {image && (
                                                        <FaDownload
                                                            onClick={() => handleDownload(image)} // Call handleDownload when icon is clicked
                                                            style={{ cursor: 'pointer', fontSize: '18px', marginLeft: '5px' }} // Add custom styles
                                                        />
                                                    )}
                                                    {/* Conditionally show image if present */}
                                                    {image && (
                                                        <div onClick={() => handleOpenImage(image)}>
                                                            <img
                                                                src={image}
                                                                alt=""
                                                                style={{ maxWidth: '100%', cursor: 'pointer' }}
                                                            />
                                                        </div>
                                                    )}
                                                    {/* Response with markdown and numbering */}
                                                    <div className="text-base leading-relaxed">
                                                        {/* Assume chat.msg contains the structured content */}
                                                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                                            {formatResponse(chat.response)}
                                                        </ReactMarkdown>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    )}

                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default DocAnalysis;
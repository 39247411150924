import React, { Suspense, useEffect, useRef, useState, useMemo } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { useGLTF, useTexture, Loader, Environment, useFBX, useAnimations, OrthographicCamera } from '@react-three/drei';
import { MeshStandardMaterial } from 'three/src/materials/MeshStandardMaterial';
import { LinearEncoding, sRGBEncoding } from 'three/src/constants';
import { LineBasicMaterial, MeshPhysicalMaterial, Vector2, PlaneGeometry } from 'three';
import ReactAudioPlayer from 'react-audio-player';
import createAnimation from '../converter';
import blinkData from '../blendDataBlink.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import './App.css';
import * as THREE from 'three';
import { json, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FaArrowLeft, FaArrowRight, FaLessThanEqual } from "react-icons/fa";
import Swal from 'sweetalert2';

const _ = require('lodash');
const host = 'https://vaani.aionasset.in';

function Avatar({ avatar_url, speak, setAudioSource, playing, blendData, setClipActions }) {

  let gltf = useGLTF(avatar_url);
  let morphTargetDictionaryBody = null;
  let morphTargetDictionaryLowerTeeth = null;
  const BlendData = blendData.blendData
  let filename = blendData.filename

  const [
    bodyDiffuseTexture,
    eyesTexture,
    teethTexture,
    bodySpecularTexture,
    bodyRoughnessTexture,
    bodyNormalTexture,
    teethNormalTexture,
    // hairDiffuseTexture,
    suitDiffuseTexture,
    suitNormalTexture,
    suitRoughnessTexture,
    hairAlphaTexture,
    hairNormalTexture,
    hairRoughnessTexture,

  ] = useTexture([
    "/train/body_diffuse.jpg",
    "/images/eyes.webp",
    "/images/teeth_diffuse.webp",
    "/train/body_specular.jpg",
    "/train/body_roughness.jpg",
    "/train/body_normal.jpg",
    "/images/teeth_normal.webp",
    // "/train/h_diffuse.jpg",
    "/train/suit_diffuse.jpg",
    "/train/suit_normal.jpg",
    "/train/suit_roughness.jpg",
    "/train/h_alpha.jpg",
    "/train/h_normal.jpg",
    "/train/h_roughness.jpg",
  ]);

  _.each([
    bodyDiffuseTexture,
    eyesTexture,
    teethTexture,
    teethNormalTexture,
    bodySpecularTexture,
    bodyRoughnessTexture,
    bodyNormalTexture,
    // hairDiffuseTexture,
    suitDiffuseTexture,
    suitNormalTexture,
    suitRoughnessTexture,
    hairAlphaTexture,
    hairNormalTexture,
    hairRoughnessTexture,
  ],
    t => { t.encoding = sRGBEncoding; t.flipY = false; });

  bodyNormalTexture.encoding = LinearEncoding;
  suitNormalTexture.encoding = LinearEncoding;
  teethNormalTexture.encoding = LinearEncoding;
  hairNormalTexture.encoding = LinearEncoding

  gltf.scene.traverse(node => {
    if (node.type === 'Mesh' || node.type === 'LineSegments' || node.type === 'SkinnedMesh') {
      node.castShadow = true;
      node.receiveShadow = true;
      node.frustumCulled = false;

      if (node.name.includes("Body")) {

        node.castShadow = true;
        node.receiveShadow = true;
        node.material = new MeshPhysicalMaterial();
        node.material.map = bodyDiffuseTexture;
        node.material.envMapIntensity = .7;
        node.material.roughness = 5;
        node.material.roughnessMap = bodyRoughnessTexture;
        node.material.normalMap = bodyNormalTexture;
        node.material.normalScale = new Vector2(0.8, 0.8);

        morphTargetDictionaryBody = node.morphTargetDictionary;

      }

      if (node.name.includes("Eyes")) {
        node.material = new MeshStandardMaterial();
        node.material.map = eyesTexture;
        // node.material.shininess = 100;
        node.material.roughness = 0.1;
        node.material.envMapIntensity = 0.5;

      }

      if (node.name.includes("Brows")) {
        node.material = new LineBasicMaterial({ color: 0x000000 });
        node.material.linewidth = 1;
        node.material.opacity = 0.5;
        node.material.transparent = true;
        node.visible = false;
      }

      if (node.name.includes("Teeth")) {

        node.receiveShadow = true;
        node.castShadow = true;
        node.material = new MeshStandardMaterial();
        node.material.roughness = 0.1;
        node.material.map = teethTexture;
        node.material.normalMap = teethNormalTexture;

        node.material.envMapIntensity = 0.9;
      }

      if (node.name.includes("Hair")) {
        node.material = new MeshStandardMaterial();
        // node.material.map = hairDiffuseTexture;
        node.material.alphaMap = hairAlphaTexture;
        node.material.normalMap = hairNormalTexture;
        node.material.roughnessMap = hairRoughnessTexture;
        node.material.roughness = 25;

        node.material.transparent = true;
        node.material.opacity = 3;
        node.material.depthWrite = false;
        node.material.side = 0;
        node.material.color.setHex(0x000000);

        node.material.envMapIntensity = 0.6;
      }

      if (node.name.includes("Suit")) {
        node.material = new MeshStandardMaterial();

        node.material.map = suitDiffuseTexture;
        node.material.roughnessMap = suitRoughnessTexture;
        node.material.roughness = 4;
        node.material.normalMap = suitNormalTexture;
        // node.material.color.setHex(0xffffff);
        // node.material.envMapIntensity = 1;
      }


      if (node.name.includes("Goggel_body")) {
        node.material = new MeshStandardMaterial({
          color: 0x000000,
          roughness: 0.7,
          metalness: 0.5,
        });
      }

      if (node.name.includes("Goggel_glass")) {
        node.material = new MeshStandardMaterial({
          color: 0xffffff,
          transparent: true,
          opacity: 0.3,
          roughness: 0.1,
          metalness: 0,
        });
      }


      if (node.name.includes("TeethLower")) {
        morphTargetDictionaryLowerTeeth = node.morphTargetDictionary;
      }
    }

  });

  const [clips, setClips] = useState([]);
  const mixer = useMemo(() => new THREE.AnimationMixer(gltf.scene), []);

  useEffect(() => {

    if (speak === false)
      return;

    console.log('hhhh', filename);
    if (BlendData.length > 0) {
      let newClips = [createAnimation(BlendData, morphTargetDictionaryBody, 'HG_Body'),
      createAnimation(BlendData, morphTargetDictionaryLowerTeeth, 'HG_TeethLower')];

      filename = host + filename;

      setClips(newClips);
      setAudioSource(filename);
    } else {
      filename = host + filename;
      setAudioSource(filename);
    }
  }, [speak]);

  let idleFbx = useFBX('/idle.fbx');
  let { clips: idleClips } = useAnimations(idleFbx.animations);

  idleClips[0].tracks = _.filter(idleClips[0].tracks, track => {
    return track.name.includes("Head") || track.name.includes("Neck") || track.name.includes("Spine2");
  });

  idleClips[0].tracks = _.map(idleClips[0].tracks, track => {
    if (track.name.includes("Head")) {
      track.name = "head.quaternion";
    }

    if (track.name.includes("Neck")) {
      track.name = "neck.quaternion";
    }

    if (track.name.includes("Spine")) {
      track.name = "spine2.quaternion";
    }
    return track;

  });

  useEffect(() => {
    let idleClipAction = mixer.clipAction(idleClips[0]);
    idleClipAction.play();

    let blinkClip = createAnimation(blinkData, morphTargetDictionaryBody, 'HG_Body');
    let blinkAction = mixer.clipAction(blinkClip);
    blinkAction.play();
  }, []);


  // Play animation clips when available
  useEffect(() => {
    if (playing === false)
      return;

    const actions = _.map(clips, clip => {
      let clipAction = mixer.clipAction(clip);
      clipAction.setLoop(THREE.LoopOnce);
      clipAction.play();
      return clipAction
    });

    setClipActions(actions)
  }, [playing]);


  useFrame((state, delta) => {
    mixer.update(delta);
  });

  return (
    <group name="avatar">
      <primitive object={gltf.scene} dispose={null} />
    </group>
  );
}

const STYLES = {
  area: { position: 'absolute', bottom: '0', left: '0', zIndex: 500, display: 'flex' },
  speak: { padding: '5px', display: 'block', color: '#FFFFFF', background: '#222222', border: 'None', right: '5px' },
  stop: { padding: '10px', left: '10px', color: 'red', background: '#FFFFF', radious: '5px' },
  label: { color: '#777777', fontSize: '0.5em' }
}

function Train() {
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [blendData, setBlendData] = useState({});
  const [authenticated, setAuthenticated] = useState(false);
  const [audioSource, setAudioSource] = useState(null);
  const [speak, setSpeak] = useState(false);
  const [playing, setPlaying] = useState(false);
  const audioPlayer = useRef();
  const [clipActions, setClipActions] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState("");
  const topics = ["Installation of LT Aerial Bunched Cable", "safety site", "transmission site", "solar power site", "insulator site"];
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [showVideoPopup, setShowVideoPopup] = useState(false);
  const [text, setText] = useState([]);
  const [videoUrl, setVideoUrl] = useState('');
  const [videoStop, setVideoStop] = useState(false);
  const videoStopRef = useRef(videoStop);
  const [quizModalVisible, setQuizModalVisible] = useState(false);
  const [showStartQuizPopup, setShowStartQuizPopup] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [isCorrect, setIsCorrect] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const {
    transcript,
    resetTranscript,
    browserSupportsSpeechRecognition,
    listening,
  } = useSpeechRecognition();
  const token = sessionStorage.getItem('yariyan_gal');
  const cred = JSON.parse(sessionStorage.getItem("cred"))
  const language = sessionStorage.getItem('lang');
  const useremail = cred.email

  useEffect(() => {
    if (!browserSupportsSpeechRecognition) {
      console.error('Browser does not support speech recognition');
      toast.error("Browser does not support speech recognition");
      return;
    }

    // Fetch credentials from session storage
    const cred = JSON.parse(sessionStorage.getItem("cred") || "[]");
    if (cred.length === 0) {
      setAuthenticated(false);
      navigate('/render'); // Redirect to login if no credentials
      return; // Prevent further execution if navigating
    }

    const greetingMessage = 'Welcome to the training site you can ask any topic related data for training purpose';

    // Call getResponse only once on initial render
    getResposnse(greetingMessage);
  }, [navigate]);

  useEffect(() => {
    if (!listening && !speak && browserSupportsSpeechRecognition) {
      SpeechRecognition.startListening();  // Start listening when audio is not playing
    }
  }, [playing, speak, listening, browserSupportsSpeechRecognition]);

  useEffect(() => {
    if (!listening && transcript) {
      handleTranscript(transcript);
      resetTranscript();
    }
  }, [transcript, listening, resetTranscript]);

  const handleTranscript = (text) => {
    try {
      console.log('Transcript:', text);

      // Convert text to lowercase for case-insensitive matching
      const lowerCaseText = text.toLowerCase();

      // Check if the text includes either 'stop' or 'not'
      if (lowerCaseText.includes('stop') || lowerCaseText.includes('not')) {
        stopButton(); // Call stop button function
      } else {
        getResposnse(text); // Continue processing as usual
      }
    } catch (e) {
      console.log("Error occurred:", e);
    }
  };

  const stopButton = async () => {
    try {

      console.log('Stop button clicked');

      audioPlayer.current.audioEl.current.pause();
      setPlaying(false);
      setAudioSource(null);
      setSpeak(false);
      if (browserSupportsSpeechRecognition && SpeechRecognition.getRecognition()) {
        SpeechRecognition.stopListening();
      }
      clipActions.forEach(action => {
        action.stop();  // Stop each action
      });

      setClipActions([]);  // Clear the actions
    } catch (e) {
      console.log("Error occured :", e);
    }
  }


  const getResposnse = async (msg) => {
    try {
      console.log("Response: ", msg);
      setLoad(true);
      const requestOptions = {
        method: 'POST',
        headers:
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },

        body: JSON.stringify({ text: msg, email: `${useremail}`, language: `${language}` }),
      };

      try {
        const response = await fetch(`${host}/train`, requestOptions);
        const result = await response.json();
        console.log("get result : ", result)
        if (result['status'] !== 'failed') {
          setBlendData(result);
          setSpeak(true);
        } else {
          toast.error("Something else");
        }
      } catch (error) {
        console.error('Error generating response:', error);
      }
      setLoad(false);
    } catch (e) {
      console.log("Something else : ", e);
    }
  };

  const fetchTrain = async () => {
    try {
      const requestOptions = {
        method: 'POST',
        headers:
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },

        body: JSON.stringify({ topic: selectedTopic, email: `${useremail}`, language: `${language}` }),
      };

      try {
        const response = await fetch(`${host}/trainData`, requestOptions);
        const result = await response.json();
        console.log("get train data : ", result)
        if (result) {
          setVideoUrl(result)
        }
      } catch (error) {
        console.error('Error generating response:', error);
      }
      setLoad(false);
    } catch (e) {
      console.log("Something else : ", e);
    }
  };


  function playerEnded() {
    try {
      setSpeak(false);
      setPlaying(false);
      setAudioSource(null);
      if (browserSupportsSpeechRecognition) {
        SpeechRecognition.startListening();  // Start listening again after audio ends
      }
    } catch (e) {
      console.log('Error occured : ', e)
    }
  }


  function playerReady() {
    try {
      const playPromise = audioPlayer.current.audioEl.current.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            setPlaying(true);
            if (browserSupportsSpeechRecognition) {
              SpeechRecognition.stopListening();  // Stop listening while audio is playing
            }
          })
          .catch((error) => {
            console.log("Audio play was prevented. User hasn't interacted yet.", error);
            alert("Please interact with the page (e.g., click a button) to allow audio playback.");
          });
      }
    } catch (e) {
      console.log("Something else : ", e);
    }
  }

  const handleLogout = () => {
    sessionStorage.setItem("authenticated", false);
    setAuthenticated(false);
    navigate("/render");
  };

  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("authenticated");
    if (loggedInUser === "true") {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
      navigate("/render", { replace: true });
    }
  }, [authenticated, navigate]);

  useEffect(() => {
    if (selectedTopic !== "") {
      // console.log("render: ", selectedTopic);
      toast.success(`You have Selected a ${selectedTopic} topic.`)
      // setText([{
      //   description: "This is the description for Topic 1.jjxs x sbdcjbk ,sd cjbsdcbkds csdcvksd csdhccdcjksdcd jcdkc jdckd mcji k cdcb ujied ckjkdc cji dck cdbc dc ed cdc jed cjed cedbdc dcj bc ecejc md chjeddjc ed dchje ec e cjeck",
      //   steps: ["Step 1: Identify hazards and classify work activities kjskc kjsjdbcksd bdkc  cuiebcew uicedbceciubcdecu uicedbic uicbewic cbecuicebcied ceuiccbkwbcebwdcbewjwc euicbewc jew cuewbucbewwjc ulewbcule ", "Step 2: Estimate and mitigate risks using engineering and administrative controls c'sdco jdncncsdc idckd c codincdoic n nkdsck oiednckkjced kc  kjkedc jdc kj ckje ck kjed kce xjkkas  jks c sakj wdkjk ck;wd cwlic eld ciec ckjqwe ck w ikqew cj cjkewc qwmecikjqec ewj cikew cqec qw cm,wdcjlqwedjlc ", "Step 3: Implement proper use of Personal Protective Equipment (PPE) iuisbdic j cdij  dci kcd scjdc j  sdj cj cj jjckdj j j cdj j jcjd ", "Step 4: Conduct regular safety training and audits ksjcjksd hj cjhusd hji cjdhj sdh jh c jdc njjh cdj jjnjsdc cjs chjlsd jc sdjc ", "Step 5:Promote a safety-first culture with strong leadership and employee engagement okscodc jdskc ik dkjc kj dck ijc edkjc kj cewdj c kjewd dckjm  jkcewr jc ijejwdf cejd cejdw cjlw, ewl cjl ecjlewd jlfc ewjlc ", "Step 6:Develop and maintain emergency response plans lklcdldsnc kjkjc ds kjk kcjkj kj k  dcj  kjsd ckk jk  c  cjed c  jlc dj c jdc jd ecj jl cedjlc jd c ljce djdc jle cjedbifeuinfd dfiueqrfdw vqeurf cjlwBFUIEQBRC ."],
      //   question: [{
      //     "content": "What is the main objective of this SOP for LT ABC installation?",
      //     "options": ["To reduce costs", "To ensure a safe and compliant installation", "To increase installation speed"],
      //     "correct_answer": "To ensure a safe and compliant installation",
      //     "feedback": {
      //       "correct": "Correct! The primary objective is to maintain safety and compliance during installation.",
      //       "incorrect": "The correct answer is to ensure a safe and compliant installation process."
      //     }
      //   }, {
      //     "content": "Which of the following standards is NOT part of the LT ABC installation guidelines?",
      //     "options": ["Electricity Act 1994", "Indian Electricity Rule 1956", "Building Code 2000"],
      //     "correct_answer": "Building Code 2000",
      //     "feedback": {
      //       "correct": "Correct! Building Code 2000 is not part of the referenced standards.",
      //       "incorrect": "The standards for LT ABC installation focus on electrical safety, not general building codes."
      //     }
      //   }, {
      //     "content": "Which item is mandatory for worker safety during LT ABC installation?",
      //     "options": ["Safety helmet with chin strap", "Noise-canceling headphones", "Flashlight"],
      //     "correct_answer": "Safety helmet with chin strap",
      //     "feedback": {
      //       "correct": "That's correct! A safety helmet with a chin strap is essential for protection.",
      //       "incorrect": "The correct answer is a safety helmet with a chin strap, crucial for head protection."
      //     }
      //   }]
      // }]);
      setText([
        {
          "submodule": "Purpose",
          "step": "instruction",
          "content": "The purpose of this training is to outline the necessary guidelines and requirements for installing LT Aerial Bunched Cable (ABC). It includes pole erection, accessory fixing, and cable stringing to ensure safety and compliance with approved standards.",
          "index": 1
        },
        {
          "submodule": "Reference Standards",
          "step": "instruction",
          "content": "Ensure that the installation adheres to the Indian Electricity Rule 1956, Electricity Act 1994, Electricity Regulation 2006, and the Electrical Safety Act 2002.",
          "index": 2
        },
        {
          "submodule": "PPE Requirements",
          "step": "instruction",
          "content": "Mandatory PPE includes a full-body harness with kinetic absorber and double lanyard with scaffolding hook, helmet with chin strap and Helmet mounted induction tester, temporary earthing system, LOTO, Kara mental Rope (for 13 mtr. Pole \u00e2\u20ac\u201c Rope Length: 25 mtr.; 09 mtr. Pole \u00e2\u20ac\u201c Rope Length: 20 mtr.), Pulley, D-shackle, Sling rope for unloading and come along clamp, and Spanners of appropriate size (2 sets). Verify PPE compliance before beginning work.",
          "index": 3
        },
        {
          "submodule": "Sequence of Construction Activities",
          "step": "instruction",
          "content": "The construction sequence includes inspecting the work area, unloading poles, marking pits, excavating, Pole Erection & Spike/Coil earthing, Fixing of Pole clamp, stay clamp, Stay set, Suspension assembly, Dead-end assembly & other hardware materials, Laying, Stringing & Jointing of Cables, Installation of 3 \u00e2\u20ac\u201c phase 4 wire JB Box & Providing connection to the existing consumers from the JB box, Clearance & Earthing, Pre- Commissioning Testing & Charging and Photographs for safe operations.",
          "index": 4
        },
        {
          "submodule": "Inspection of Work Area & Shifting / unloading of poles",
          "step": "instruction",
          "content": "Inspect the work place, before the work to be started, Poles, Cables and accessories need to be shifted to the working location without any damage. All materials will be verified by Engineer/Supervisor In-Charge & must comply the approved standards & specifications set",
          "index": 5
        },
        {
          "submodule": "Pit Marking, Excavation & other civil related works",
          "step": "instruction",
          "content": "The Pit marking shall be carried out as per the survey co-ordinates approved and  in consultation with Engineer In-Charge,  Any deviation in the markings shall be brought into notice, Normally the span length is to be kept 30-35 mtr, but where there is way leaf problem, the span length can be modified by maintaining minimum ground clearance, If stringing work is carried out in the existing circuit on old poles then intermediate/Dead end poles shall be installed to maintain standard span length, The Size of the pit shall be of (600 x 1500) mm., Ensure dimension of RCC base plate is 450x450x75mm as per approved drawing ",
          "index": 6
        },
        {
          "submodule": "Pole Erection",
          "step": "instruction",
          "content": "Erect poles either manually or with machinery. Assembly of the Pole i.e., fixing of pole clamp, stay clamp, suspension clamp, dead-end clamp and I-hook. Use a spirit level for vertical alignment and secure poles with temporary stays before concreting with RCC mix (1:1.5:3) to ground level.",
          "index": 7
        },
        {
          "submodule": "Earthing",
          "step": "instruction",
          "content": "Each pole requires earthing with two 7/10 SWG GI wires, using either pipe, coil, or spike earthing based on site conditions.",
          "index": 8
        },
        {
          "submodule": "Fixing Accessories",
          "step": "instruction",
          "content": "The Stay/Guys shall be used at the following pole locations; - all the tapping points, dead-end poles, crossings, and at all the points for the diversion angle of more than 10\u00c2\u00b0. Stay set shall be installed before proceeding to the stringing activity. An angle between 30\u00c2\u00b0 to 45\u00c2\u00b0 shall be maintained between the stay wire and the pole. At locations, where stay wire cannot be obtained especially stays such as foot stay, flying stay or struts may be used. Pole clamp with I-hook & other assemblies to be fixed as per the approved drawings. Dead-end clamp shall be used at tapping pole, dead-end pole & where the line deflection angle is more than 15\u00c2\u00b0. If the LT circuit goes straight without any deviation or cut-point, then a dead-end must be used after a span length of 250 Mtr. For different cable sizes & combinations, an appropriate rating of dead-end & suspension shall be used, i.e. Suspension clamp for 70-90 sqmm size shall be used for 90 sqmm LT ABC. Danger Board at 3000 mm from ground level. Pole Numbering shall be done as per standards. ",
          "index": 9
        },
        {
          "submodule": "Fixing Accessories",
          "step": "media",
          "content": "This image illustrates the correct orientation of a suspension clamp with its holding point facing the pole.",
          "media_type": "image",
          "media_path": "/path/to/suspension_clamp_orientation_image.jpg",
          "index": 10
        },
        {
          "submodule": "Fixing Accessories",
          "step": "instruction",
          "content": "At diversion points, use a stay set with a suspension clamp. This setup stabilizes the line when the direction changes.",
          "index": 11
        },
        {
          "submodule": "Fixing Accessories",
          "step": "media",
          "content": "Here is an image of a stay set with a suspension clamp used at a diversion point.",
          "media_type": "image",
          "media_path": "/path/to/stay_set_suspension_clamp_image.jpg",
          "index": 12
        },
        {
          "submodule": "Fixing Accessories",
          "step": "instruction",
          "content": "At cut-points, where one circuit continues on a suspension clamp and another on a dead-end clamp, ensure each clamp is properly installed for stable line management.",
          "index": 13
        },
        {
          "submodule": "Fixing Accessories",
          "step": "media",
          "content": "This image shows the setup of a cut-point with one circuit using a suspension clamp and the other using a dead-end clamp.",
          "media_type": "image",
          "media_path": "/path/to/cut_point_suspension_deadend_image.jpg",
          "index": 14
        },
        {
          "submodule": "Fixing Accessories",
          "step": "instruction",
          "content": "Use separate pole clamps for the suspension clamp and the dead-end clamp to ensure each line component has adequate support.",
          "index": 15
        },
        {
          "submodule": "Fixing Accessories",
          "step": "media",
          "content": "This image demonstrates the installation of separate pole clamps for suspension and dead-end clamps.",
          "media_type": "image",
          "media_path": "/path/to/separate_pole_clamps_image.jpg",
          "index": 16
        },
        {
          "submodule": "Dead-end Pole Setup",
          "step": "instruction",
          "content": "Install a dead-end pole with a stay set, a service connection junction box, and a dead-end clamp to secure the end of the line and provide a stable connection point for services.",
          "index": 17
        },
        {
          "submodule": "Dead-end Pole Setup",
          "step": "media",
          "content": "Here is an image showing the dead-end pole setup, complete with stay set, junction box, and dead-end clamp.",
          "media_type": "image",
          "media_path": "/path/to/deadend_pole_junction_box_image.jpg",
          "index": 18
        },
        {
          "submodule": "Laying, Stringing & Jointing of Cables",
          "step": "instruction",
          "content": "While transporting cable drums to the site, ensure precautions are taken so that the conductor does not get damaged.",
          "index": 19
        },
        {
          "submodule": "Laying, Stringing & Jointing of Cables",
          "step": "instruction",
          "content": "Use a turntable or cable jack during cable laying. Ensure that while drawing the cable, it does not rub against surfaces causing damage. Lay cables using a cable roller.",
          "index": 20
        },
        {
          "submodule": "Laying, Stringing & Jointing of Cables",
          "step": "instruction",
          "content": "Perform mid-span jointing using IPC (Insulation Piercing Connector). Ensure no more than one joint is made in one span, and no joints are made over main roads, railway lines, or river spans.",
          "index": 21
        },
        {
          "submodule": "Laying, Stringing & Jointing of Cables",
          "step": "instruction",
          "content": "Place M.S. (mid-span) joints at least 15 meters away from line poles or structures. PG clamps may be preferred for the jointing of messenger cables.",
          "index": 22
        },
        {
          "submodule": "Laying, Stringing & Jointing of Cables",
          "step": "instruction",
          "content": "Provide temporary stays while sagging the cables. Ensure that there is no more than a 15\u00c2\u00b0 diversion angle between two dead-end places during sagging.",
          "index": 23
        },
        {
          "submodule": "Laying, Stringing & Jointing of Cables",
          "step": "instruction",
          "content": "Ensure all road/river crossings are made at a 90\u00c2\u00b0 angle (perpendicular to the road), irrespective of the span length.",
          "index": 24
        },
        {
          "submodule": "Laying, Stringing & Jointing of Cables",
          "step": "instruction",
          "content": "Carry out all other works as per approved standards and in consultation with the Engineer In-Charge.",
          "index": 25
        },
        {
          "submodule": "Installation of 3-Phase JB Box & Dismantling of Existing Line Conductor",
          "step": "instruction",
          "content": "Install 3-phase JB box on each pole to provide service connections to consumer houses.",
          "index": 26
        },
        {
          "submodule": "Installation of 3-Phase JB Box & Dismantling of Existing Line Conductor",
          "step": "instruction",
          "content": "Use single core cables for connecting the JB box from LT ABC using IPC (Insulating Piercing Connector). Properly dress the cables using cable ties.",
          "index": 27
        },
        {
          "submodule": "Installation of 3-Phase JB Box & Dismantling of Existing Line Conductor",
          "step": "instruction",
          "content": "Limit the number of service connections to a maximum of 12 from one JB box. If more than 10 connections are needed, install two JB boxes.",
          "index": 28
        },
        {
          "submodule": "Installation of 3-Phase JB Box & Dismantling of Existing Line Conductor",
          "step": "instruction",
          "content": "Identify the existing LT line to be dismantled, barricade the area with warning tapes and safety cones, and take the necessary shutdowns. Ensure no damage occurs to conductors, insulators, or fittings during dismantling.",
          "index": 29
        },
        {
          "submodule": "Clearance Standards",
          "step": "instruction",
          "content": "Maintain a minimum ground clearance of 6.5 meters over roads under maximum sag conditions. Confirm compliance with relevant authorities like NHAI.  All the pole accessories along with messenger wire should be properly earthed as per the approved drawing & standards.",
          "index": 30
        },
        {
          "submodule": "Pre-commissioning Testing",
          "step": "instruction",
          "content": "Before charging, conduct a thorough alignment check of poles,  concreting and muffing, clamps and jumpers, cables and ground wire, inspect earthing systems  whether the earthing connections of supports and fittings are intact, Measure earth resistance with an earth tester and test cable continuity using a megger or insulation resistance tester.",
          "index": 31
        },
        {
          "submodule": "Understanding Check",
          "step": "question",
          "content": "Are you understanding the installation steps so far?",
          "options": [
            "Yes",
            "No",
            "Need clarification"
          ],
          "correct_answer": "Yes",
          "feedback": {
            "correct": "Great! Let's continue.",
            "incorrect": "Feel free to ask for clarification on any step."
          },
          "index": 32
        },
        {
          "submodule": "Tool & Equipment Check",
          "step": "question",
          "content": "Do you have all required tools and equipment for the installation?",
          "options": [
            "Yes",
            "No"
          ],
          "correct_answer": "Yes",
          "feedback": {
            "correct": "Good! Always check tools before beginning work.",
            "incorrect": "Ensure all necessary tools and PPE are ready for safe installation."
          },
          "index": 33
        },
        {
          "submodule": "Condition Check",
          "step": "question",
          "content": "Are the tools and equipment in good condition?",
          "options": [
            "Yes",
            "No"
          ],
          "correct_answer": "Yes",
          "feedback": {
            "correct": "Excellent! Proper maintenance is essential for safety.",
            "incorrect": "Please ensure all equipment is in good condition before use."
          },
          "index": 34
        },
        {
          "submodule": "Pole Erection",
          "step": "question",
          "content": "What tool is used to ensure proper vertical alignment of a pole during installation?",
          "options": [
            "Spirit level",
            "Compass",
            "Rope"
          ],
          "correct_answer": "Spirit level",
          "feedback": {
            "correct": "Correct! A spirit level ensures accurate alignment.",
            "incorrect": "The correct answer is Spirit level, which is essential for alignment."
          },
          "index": 35
        },
        {
          "submodule": "Fixing Accessories",
          "step": "question",
          "content": "Which direction should the holding point of a suspension clamp face during installation?",
          "options": [
            "Away from the pole",
            "Toward the pole",
            "Parallel to the cable"
          ],
          "correct_answer": "Toward the pole",
          "feedback": {
            "correct": "Correct! Facing the holding point toward the pole stabilizes the clamp.",
            "incorrect": "The correct answer is toward the pole, which stabilizes the suspension clamp."
          },
          "index": 36
        },
        {
          "submodule": "Cut-point Configuration",
          "step": "question",
          "content": "At a cut-point, what is the recommended configuration for clamps?",
          "options": [
            "Both circuits on dead-end clamps",
            "One circuit on suspension, one on dead-end clamp",
            "Both circuits on suspension clamps"
          ],
          "correct_answer": "One circuit on suspension, one on dead-end clamp",
          "feedback": {
            "correct": "Correct! This configuration ensures stability at cut-points.",
            "incorrect": "The recommended setup is to use one suspension and one dead-end clamp at cut-points."
          },
          "index": 37
        }
      ])

      setCurrentStepIndex(0);
      setVideoUrl("https://www.w3schools.com/html/mov_bbb.mp4")
      setShowPopup(true);
      setVideoStop(true);
      // getResposnse(selectedTopic);
    }
  }, [selectedTopic]);

  // console.log('desc : ', text[0]['description'])

  const handleConfirmStep = (confirmed) => {
    if (confirmed) {
      if (currentStepIndex < text[0].steps.length - 1) {
        setCurrentStepIndex(currentStepIndex + 1); // Move to the next step
        setShowPopup(true); // Show popup for the next step
      } else {
        setVideoStop(false);
        Swal.fire({
          title: "Congratulations!",
          text: "You have completed all steps!",
          icon: "success",
          confirmButtonText: "Awesome!",
          confirmButtonColor: "#4CAF50",
        });
      }
    } else {
      Swal.fire({
        title: "Step Canceled",
        text: "You have chosen to cancel this step.",
        icon: "info",
        confirmButtonText: "OK",
        confirmButtonColor: "#f44336",
      });
    }
    setShowPopup(false); // Close popup after response
  };

  const handlePreviousStep = () => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1); // Go back to the previous step
    }
    setShowPopup(false); // Close popup
  };

  console.log('ss: ', currentStepIndex);

  useEffect(() => {
    videoStopRef.current = videoStop;
  }, [videoStop]);

  // Set interval to show video popup every minute if videoStop is true
  useEffect(() => {
    const interval = setInterval(() => {
      console.log("vid : ", videoStopRef.current);
      if (videoStopRef.current) {
        setShowVideoPopup(true);
      }
    }, 60000); // Check every 1 minute

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  const handleConfirmVideo = (confirmed) => {
    if (confirmed) {
      console.log('yes you are confirmed');
    }
    setShowVideoPopup(false);
  };


  // const handleAnswer = (selectedOption) => {
  //   const correctAnswer = text[0].question[currentQuestionIndex].correct_answer;
  //   if (selectedOption === correctAnswer) {
  //     alert(text[0].question[currentQuestionIndex].feedback.correct);
  //   } else {
  //     alert(text[0].question[currentQuestionIndex].feedback.incorrect);
  //   }
  // };

  const handleAnswer = (option) => {
    const correctAnswer = text[0].question[currentQuestionIndex].correct_answer;
    setSelectedOption(option);

    if (option === correctAnswer) {
      setFeedbackMessage(text[0].question[currentQuestionIndex].feedback.correct);
      setIsCorrect(true);
    } else {
      setFeedbackMessage(text[0].question[currentQuestionIndex].feedback.incorrect);
      setIsCorrect(false);
    }
  };


  console.log("vvv: ", videoUrl);

  return (
    <div className="full">
      <ToastContainer
        position="top-left"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <button className="logout-button" onClick={handleLogout}><FontAwesomeIcon icon={faSignOutAlt} />
        <span className="logout-text">Logout</span></button>

      {/* Main content */}
      <div className='main-content'>
        <div style={STYLES.area}>
          <button style={STYLES.speak}>
            {speak || load ? 'Thinking...' : 'Lets Speak.'}
          </button>
        </div>

        <div className="train-div">
          <div className="topic-buttons">
            <label>Select A Topic :</label>
            <div>
              {topics.map((topic, index) => (
                <button
                  key={index}
                  className={`topic-button ${selectedTopic === topic ? "active" : ""}`}
                  onClick={() => setSelectedTopic(topic)}
                >
                  {topic}
                </button>
              ))}
            </div>
          </div>
          {/* Display topic description */}
          {text.length > 0 && (
            <div className="topic-details">
              {text.map((step, index) => (
                <div key={index} className="step-container">
                  {step.submodule == 'Purpose' && (
                    <div className="desc">
                      Description : {step.content}
                    </div>
                  )}
                  {step.step == 'media' && step.media_type == 'image' && (
                    <img src={step.media_path} alt={step.submodule} style={{ maxWidth: '100%' }} />
                  )}

                  {step.step == 'media' && step.media_type == 'video' && (
                    <>
                      {/* When video is detected, set video URL and show the popup */}
                      {setShowVideoPopup(true)}
                      {setVideoUrl(step.media_path)}
                    </>
                  )}
                  {step.step == 'instruction' && step.submodule != 'pupose' && (
                    <div className="steps">
                      <p>{step.content}</p>
                    </div>
                  )}

                  {step.step == 'question' && (
                    <>
                      {setShowStartQuizPopup(true)}
                      {quizModalVisible && (
                        <div className="quiz-popup-overlay">
                          <div className="quiz-popup-content">
                            <h2>Question</h2>
                            <p className="quiz-question">{step.content}</p>
                            <div className="quiz-options">
                              {step.options.map((option, index) => (
                                <button
                                  key={index}
                                  className={`quiz-option ${selectedOption === option
                                    ? isCorrect === true
                                      ? 'correct-option'
                                      : isCorrect === false
                                        ? 'incorrect-option'
                                        : ''
                                    : ''
                                    }`}
                                  onClick={() => handleAnswer(option)}
                                  disabled={selectedOption !== null} // Disable after selecting an option
                                >
                                  {option}
                                </button>
                              ))}
                            </div>

                            {feedbackMessage && (
                              <div className="quiz-feedback">
                                <p>{feedbackMessage}</p>
                              </div>
                            )}
                          </div>
                        </div>

                      )}
                    </>

                  )}
                </div>
              ))}
            </div>
          )}
          {showPopup && (
            <div className="popup-overlay">
              <div className="popup-content">
                <p className="popup-question">Do you want to proceed with {currentStepIndex + 1} Step. </p>
                <div className="popup-buttons">
                  <button className="popup-button confirm" onClick={() => handleConfirmStep(true)}>Yes</button>
                  <button className="popup-button cancel" onClick={() => handleConfirmStep(false)}>No</button>
                </div>
              </div>
            </div>
          )}

          {/* Navigation Icons */}
          <div className="navigation-buttons">
            {currentStepIndex > 0 && (
              <FaArrowLeft className="nav-icon previous" onClick={handlePreviousStep} />
            )}
            {text.length > 0 && currentStepIndex < text.length && (
              <FaArrowRight className="nav-icon next" onClick={() => setShowPopup(true)} />
            )}
          </div>
        </div>

        {/* Popup for Video Confirmation */}
        {showVideoPopup && (
          <div className="popup">
            <div className="popup-video">
              <span className="close-video" onClick={() => handleConfirmVideo(false)}>
                &times;
              </span>
              <p>Do you want to see this video?</p>
              {videoUrl && (
                <video width="400" height="300" controls>
                  <source src={videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          </div>
        )}

        {showStartQuizPopup && (
          <div className="confirmation-popup-overlay">
            <div className="confirmation-popup-content">
              <h3>Start Quiz</h3>
              <p>Are you ready to start the quiz?</p>
              <div className="confirmation-buttons">
                <button
                  className="confirm-button"
                  onClick={() => {
                    setQuizModalVisible(true);
                    setShowStartQuizPopup(false); // Close confirmation modal
                  }}
                >
                  Yes
                </button>
                <button
                  className="cancel-button"
                  onClick={() => setShowStartQuizPopup(false)} // Close confirmation modal
                >
                  No
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <ReactAudioPlayer
        src={audioSource}
        ref={audioPlayer}
        onEnded={playerEnded}
        onCanPlayThrough={playerReady}
      />
      <Canvas
        dpr={2}
        onCreated={(ctx) => {
          ctx.gl.physicallyCorrectLights = true;
        }}
      >
        <OrthographicCamera makeDefault zoom={1300} position={[0, 1.65, 1]} />
        <Suspense fallback={null}>
          <Environment background={false} files="/images/photo_studio_loft_hall_1k.hdr" />
        </Suspense>
        <Suspense fallback={null}>
          <Bg />
        </Suspense>
        <Suspense fallback={null}>
          <Avatar
            avatar_url="/rohit.glb"
            speak={speak}
            setSpeak={setSpeak}
            setAudioSource={setAudioSource}
            playing={playing}
            blendData={blendData}
            setClipActions={setClipActions}
          />
        </Suspense>
      </Canvas>
      <Loader dataInterpolation={(p) => `Loading... please wait`} />
    </div>
  );
}

function Bg() {

  const texture = useTexture('/images/training.png');

  return (
    <mesh position={[0, 1.8, -1]} scale={[1.2, 0.9, 1]}>
      <planeGeometry args={[]} />
      <meshBasicMaterial map={texture} />

    </mesh>
  )
}
export default Train;
import React, { useState } from 'react';
import './App.css'; // Main CSS file
import SignupModel from './SignupModel'; // Modal component
import LoginModel from './LoginModel';
// import { json, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

const Renderpage = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalType, setModalType] = useState('signup'); // Track which modal is open
    const [nav, setNav] = useState('');
    const token = sessionStorage.getItem('yariyan_gal');
    const cred = JSON.parse(sessionStorage.getItem("cred"))


    const openModal = (type) => {
        setModalType(type);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalType('');
    };

    const checkTrain = () => {
        try {
            if (cred != null) {
                if (cred.length > 0 && token) {
                    setNav('/train')
                }
                else {
                    toast.info('Please do login or take subscription');
                    setNav('/train')
                    openModal('login');
                }
            } else {
                setNav('/train')
                openModal('login');
            }

        } catch (e) {
            console.log("Some thing went wrong!");
            toast.error('Some thing went wrong!');
        }
    }

    const checkQuestion = () => {
        try {
            if (cred != null) {
                if (cred.length > 0 && token) {
                    setNav('/chat')
                }
                else {
                    toast.info('Please do login or take subscription');
                    setNav('/chat')
                    openModal('login');
                }
            } else {
                setNav('/chat')
                openModal('login');
            }

        } catch (e) {
            console.log("Some thing went wrong!");
            toast.error('Some thing went wrong!');
        }

    }

    const checkAnalysis = () => {
        try {
            console.log('hello hi how are you');
            if (cred != null) {
                if (cred.length > 0 && token) {
                    setNav('/doc_upload');
                }
                else {
                    toast.info('Please do login or take subscription');
                    setNav('/doc_upload');
                    openModal('login');
                }
            } else {
                setNav('/doc_upload');
                openModal('login');
            }

        } catch (e) {
            console.log("Some thing went wrong!");
            toast.error('Some thing went wrong!');
        }

    }

    const checkImage = () => {
        try {
            console.log('hello hi how are you');
            console.log('ccc:', cred);
            if (cred != null) {
                if (cred.length > 0 && token) {
                    setNav('/img_analysis');
                }
                else {
                    toast.info('Please do login or take subscription');
                    setNav('/img_analysis');
                    openModal('login');
                }
            }
            else {
                setNav('/img_analysis');
                openModal('login');
            }
        } catch (e) {
            console.log("Some thing went wrong!");
            toast.error('Some thing went wrong!');
        }
    }

    const Advance_tool = () => {
        try {
            console.log('hello hi how are you');
            console.log('ccc:', cred);
            if (cred != null) {
                if (cred.length > 0 && token) {
                    setNav('/advance_tool');
                }
                else {
                    toast.info('Please do login or take subscription');
                    setNav('/advance_tool');
                    openModal('login');
                }
            }
            else {
                setNav('/advance_tool');
                openModal('login');
            }
        } catch (e) {
            console.log("Some thing went wrong!");
            toast.error('Some thing went wrong!');
        }
    }

    return (
        <div className="app">
            {/* Header with Sign Up and Login */}
            <header className="header">
                <div className="logo">Safety.ai</div>
                <div className="header-buttons">
                    <button className="header-btn" onClick={() => openModal('signup')}>
                        Sign Up
                    </button>
                    {/* <button className="header-btn" onClick={() => openModal('login')}>
                        Login
                    </button> */}
                </div>
            </header>

            {/* Main content */}
            <main className="main-content">
                <div className="main-text">What do you want to do?</div>
                <div className="card-container">
                    <div className="card" onClick={checkTrain}>
                        <h3>Training site</h3>
                        <p>I'm a trainer for give details answer with safety , power, etc . here i am for help you</p>
                        <p>Reply...</p>
                    </div>
                    <div className="card" onClick={checkQuestion}>
                        <h3>Question Answer</h3>
                        <p>I'm able to give answer of your question if you have a safety related question in understandable way..</p>
                        <p>Reply...</p>
                    </div>
                    <div className="card" onClick={checkAnalysis}>
                        <h3>Document Analysis</h3>
                        <p>I'm the document analyzer . i will help you for give answer of any document related question and csv/xls file analysis with generate a graph</p>
                        <p>Reply...</p>
                    </div>
                    <div className="card" onClick={checkImage}>
                        <h3>Image Analytics</h3>
                        <p>I'm the image analyzer . i will help you for give missing Analytics of any image or webcam capture photo or sugggest how to safe it is missing thing.</p>
                        <p>Reply...</p>
                    </div>
                    <div className="card" onClick={Advance_tool}>
                        <h2 className="blink">Advance Pro tool</h2>
                        <p>This is a advance pro tool for anlyze data from database,pdf,Excel,Website etc.</p>
                        <p>Reply...</p>
                    </div>
                </div>
            </main>

            {/* Conditionally render the modal based on modalType */}
            {isModalOpen && modalType === 'signup' && <SignupModel closeModal={closeModal} openModal={openModal} />}
            {isModalOpen && modalType === 'login' && <LoginModel closeModal={closeModal} openModal={openModal} getNav={nav} />}
        </div>
    );
};

export default Renderpage;

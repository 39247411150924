import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import './Model.css'; // Modal-specific styles
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoginModel = ({ closeModal, getNav }) => {
    const navigate = useNavigate();
    const [form, setForm] = useState({});
    const [secure, setSecure] = useState({ user: 'AION@2023', pass: 'VA@05-07-2023' });
    const [cred, setCred] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const [authenticated, setAuthenticated] = useState(false);

    const handleForm = (e) => {
        // console.log(e.target.name);
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleNavigation = (path) => {
        navigate(path);
    };
    console.log('hi nav get : ', getNav);
    console.log("lang : ", form);

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            if (!form.language) {
                setErrorMessage('Please select a language.');
                return; // Prevent form submission
            }
            sessionStorage.setItem("lang", form.language);
            const response = await fetch('https://vaani.aionasset.in/login', {
                method: 'POST',
                body: JSON.stringify({ ...form, ...secure }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            const data = await response.json();
            console.log("ssxx", data);
            if (data.user) {
                console.log("yes data get")
                setCred({
                    name: data.user.name,
                    email: data.user.email,
                    uid: data.user.uid,
                    purpose: data.user.purpose,
                });
                sessionStorage.setItem("authenticated", true);
                sessionStorage.setItem("yariyan_gal", data.access_token);
                setAuthenticated(true);
            }
            else {
                toast.error(data.msg);
            }
        } catch (err) {
            console.log("error:", err)
            toast.error("something went wrong");
        }
    }

    sessionStorage.setItem("cred", JSON.stringify(cred));
    // console.log("cred in", cred);
    useEffect(() => {
        // Check if the user is already authenticated using the token in sessionStorage
        const token = sessionStorage.getItem("yariyan_gal");
        const creds = JSON.parse(sessionStorage.getItem("cred") || "[]");
        console.log("hi : ", creds.uid);
        if (token && authenticated && creds.uid) {
            console.log("hello ready for go navigate");
            console.log("hi getnav : ", getNav);
            if (getNav != '') {
                sessionStorage.setItem("subpurpose", getNav);
                navigate(getNav);
            } else {
                navigate('/welcome');
            }
        } else {
            console.log('something went wrong');
            // toast.error("something went wrong");
        }
    }, [authenticated]);

    return (
        <div className="modal-backdrop">
            <ToastContainer position="top-left" autoClose={4000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
            <div className="modal">
                <button className="close-btn" onClick={closeModal}>
                    &times;
                </button>
                <h2>Login</h2>
                <form onSubmit={handleSubmit} className='modal-form'>

                    <div className="form-group">
                        <input
                            type="email"
                            name="email"
                            value={form.email}
                            onChange={handleForm}
                            placeholder="Email"
                            required
                            className="modal-input"
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="password"
                            name="password"
                            value={form.password}
                            onChange={handleForm}
                            placeholder="Password"
                            required
                            className="modal-input"
                        />
                    </div>

                    <div className="form-group">
                        {/* <label htmlFor="language">Choose Language:</label> */}
                        <select
                            id="language"
                            name="language"
                            value={form.language}
                            onChange={handleForm}
                            className="modal-input language-dropdown"
                            required
                        >
                            <option value="" >Select Language</option>
                            <option value="hi">Hindi</option>
                            <option value="en">English</option>
                            <option value="bn">Bengali</option>
                            <option value="te">Telugu</option>
                            <option value="mr">Marathi</option>
                            <option value="ta">Tamil</option>
                            <option value="gu">Gujarati</option>
                            <option value="kn">Kannada</option>
                            <option value="or">Odia</option>
                        </select>
                    </div>
                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                    <button type="submit" className="auth-button">Submit</button>
                    <button type="button" className="auth-button" onClick={() => handleNavigation("/sendotp")}>Forgot Password</button>
                </form>
                <p className="privacy-policy">
                    {/* Don't have Account? Create <a href="/render">Signup</a>. */}
                    Don't have Account? Create <a href="/render">Signup</a>.
                </p>
            </div>
        </div>
    );
};

export default LoginModel;

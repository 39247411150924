import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import './Login.css';
// import '../Common.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

Modal.setAppElement('#root');  // Set the root element for modal

const SendOtp = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({});
  const [secure] = useState({ user: 'AION@2023', pass: 'VA@05-07-2023' });
  const [otp, setOtp] = useState(['', '', '', '']);  // For OTP input
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);  // OTP modal visibility
  const [ispassModelOpen, setIsPassModelOpen] = useState(false); //Password fill model
  const [pass, setPass] = useState({});

  const handleForm = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handlePass = (e) => {
    setPass({
      ...pass,
      [e.target.name]: e.target.value
    });
  };


  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const response = await fetch('https://vaani.aionasset.in/checkemail', {
        method: 'POST',
        body: JSON.stringify({ ...form, ...secure }),
        headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*",
        }
      });
      const res = await response.json()
      console.log("data send : ", res);
      if (res.status === "success") {
        // Email exists, open OTP modal
        console.log("open model");
        setIsOtpModalOpen(true);
      } else {
        toast.error("Email does not exists ?");
      }

    } catch (err) {
      console.log('error', err);
    }
  };
  console.log('state : ', isOtpModalOpen)

  const handleOtpChange = (e, index) => {
    const value = e.target.value;

    // Only accept numeric values
    if (/^[0-9]$/.test(value) || value === '') {
      let otpArray = [...otp];
      otpArray[index] = value;  // Update the OTP digit

      setOtp(otpArray);

      // If there's input and it's not the last box, move to the next input
      if (value && index < otp.length - 1) {
        document.getElementById(`otpInput-${index + 1}`).focus();
      }

      // If backspace is pressed and the current input is empty, move to the previous input
      if (value === '' && index > 0) {
        document.getElementById(`otpInput-${index - 1}`).focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    // Handle Backspace key to go back to the previous input
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      document.getElementById(`otpInput-${index - 1}`).focus();
    }
  };

  const handleOtpSubmit = async () => {
    const otpValue = otp.join('');
    if (otpValue.length < 4 || otp.includes('')) {
      // If not, show alert and highlight the inputs
      toast.error('Please fill complete otp ?');
      console.log('OTP Entered:', otpValue);
    }
    else {
      // Handle OTP validation logic here
      try {
        console.log('OTP Entered:', otpValue);
        const response = await fetch('https://vaani.aionasset.in/matchotp', {
          method: 'POST',
          body: JSON.stringify({ ...form, ...secure, otpValue }),
          headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
          }
        });
        const res = await response.json()
        console.log("data send : ", res);
        if (res.status === "success") {
          // Email exists, open OTP modal
          console.log("otp match");
          setIsOtpModalOpen(false);
          toast.success("Otp match successfully");
          setIsPassModelOpen(true);
          // navigate("/forgot")
        } else {
          let mm = res.message;
          toast.error(mm);
        }
      } catch (err) {
        console.log('error', err);
      }
    }
  };

  const handlePassSubmit = async () => {
    if (pass.password != pass.confirm_password) {
      // If not, show alert and highlight the inputs
      toast.error('Password does not match');
      console.log('email:', form.email);
    }
    else {
      // Handle OTP validation logic here
      try {
        console.log('password new', pass);
        const response = await fetch('https://vaani.aionasset.in/changePass', {
          method: 'POST',
          body: JSON.stringify({ ...form, ...secure, ...pass }),
          headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
          }
        });
        const res = await response.json()
        console.log("data send : ", res);
        if (res.status === "success") {
          // Email exists, open OTP modal
          console.log("Password change successfully");
          toast.success("Password change successfully");
          setIsPassModelOpen(false);
          navigate("/render")
        } else {
          let mm = res.message;
          toast.error(mm);
        }
      } catch (err) {
        console.log('error', err);
      }
    }
  };


  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className='form'>
        <form onSubmit={handleSubmit} className="loginForm flex flex-col items-center gap-y-3">
          <h2 className='text-3xl font-semibold text-black mb-4'>Reset Your Password</h2>
          <div className="flex flex-col w-10/12 items-center gap-y-3 mb-4">
            <input
              className="w-full rounded-md text-[#151515] font-medium p-2 bg-white  focus:outline-none border-black border-[1px] focus:border-[2px] transition ease-in-out  duration-100"
              type="email"
              placeholder="Email"
              required
              name="email"
              onChange={handleForm}
            />
          </div>
          <div className="flex flex-col w-10/12 items-center gap-y-3">
            <button className="bg-[#007CE4] py-2 text-white w-full  font-semibold" type="submit">Submit</button>
          </div>
          <div className='loginspan py-2'>&copy; 2024 Arcturus Business Solutions</div>
        </form>
      </div>

      {/* OTP Modal */}
      <Modal
        isOpen={isOtpModalOpen}
        onRequestClose={() => setIsOtpModalOpen(false)}
        contentLabel="Enter OTP"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <h2>Enter OTP</h2>
        <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
          {otp.map((digit, index) => (
            <input
              key={index}
              id={`otpInput-${index}`}
              type="text"
              value={digit}
              maxLength="1"
              onChange={(e) => handleOtpChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              style={{ width: '40px', height: '40px', textAlign: 'center', fontSize: '20px' }}
            />
          ))}
        </div>
        <button onClick={handleOtpSubmit} style={{ marginTop: '20px', marginLeft: "25%", backgroundColor: "yellow", width: '50%' }}>
          Submit OTP
        </button>
      </Modal>

      {/* password changed model  */}
      <Modal
        isOpen={ispassModelOpen}
        onRequestClose={() => setIsPassModelOpen(false)}
        contentLabel="Enter Password"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <h2>Enter password or Confirm Password</h2>
        <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
          <input className="w-full rounded-md text-[#151515] font-medium p-2 bg-white  focus:outline-none border-black border-[1px] focus:border-[2px] transition ease-in-out  duration-100" type="password" placeholder="Password" required name="password" onChange={handlePass} />
          <input className="w-full rounded-md text-[#151515] font-medium p-2 bg-white  focus:outline-none border-black border-[1px] focus:border-[2px] transition ease-in-out  duration-100" type="password" placeholder="Confirm Password" required name="confirm_password" onChange={handlePass} />
        </div>
        <button onClick={handlePassSubmit} style={{ marginTop: '20px', marginLeft: "25%", backgroundColor: "yellow", width: '50%' }}>
          Change Password
        </button>
      </Modal>
    </div>
  );
};

export default SendOtp;

import React, { useState, useEffect } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./comp.css"; // You need to add this file for custom styles
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'; // To handle tables and more
import 'react-toastify/dist/ReactToastify.css';
import { saveAs } from 'file-saver'; // For downloading the image
import { FaDownload } from 'react-icons/fa';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // Import the Lightbox styles

function AssistantPanel({ onClose }) {
    const [chatHistory, setChatHistory] = useState([]);
    const [userInput, setUserInput] = useState("");
    const [image, setImage] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [chatId, setChatId] = useState();
    const [history, setHistory] = useState([]);
    const [messages, setMessages] = useState([]);

    const cred = JSON.parse(sessionStorage.getItem("cred"));
    const userId = cred.uid;
    const useremail = cred.email;
    const token = sessionStorage.getItem('yariyan_gal');
    let language = sessionStorage.getItem('lang');
    const subscription = sessionStorage.getItem('subpurpose');
    const sanitizedSubscription = subscription.replace(/^\//, ''); // Remove the leading '/'
    console.log("sub : ", sanitizedSubscription);

    const handleSend = async () => {
        if (userInput.trim()) {
            setChatHistory(prevChatHistory => {
                const newChatHistory = [...prevChatHistory, { id: prevChatHistory.length + 1, message: userInput }];

                return newChatHistory;
            });
            let localChatId = chatId;
            if (!chatId) {
                localChatId = uuidv4();
                console.log('cc : ', localChatId);
                setChatId(localChatId);
            }
            const toastadv = toast.loading("Please wait...");
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ prompt: userInput, cid: localChatId, user_id: `${userId}`, email: `${useremail}`, language: `${language}`, subscription: `${sanitizedSubscription}` })
            };

            try {
                const response = await fetch('https://vaani.aionasset.in/generate_advance', requestOptions);
                const result = await response.json();
                console.log('res : ', result);
                const text = result.response;
                setChatHistory(prevChatHistory => {
                    const newChatHistory = [...prevChatHistory];

                    // Update the last element with the response
                    const lastElementIndex = newChatHistory.length - 1;
                    newChatHistory[lastElementIndex] = {
                        ...newChatHistory[lastElementIndex],
                        response: text // Add the response to the last element
                    };

                    return newChatHistory;
                });

                toast.update(toastadv, { render: "Success!", type: "success", isLoading: false, autoClose: 500 });
                fetchChatHistoryByUserId();
            } catch (error) {
                console.error('Error generating response:', error);
                toast.update(toastadv, { render: "Failed to generate response!", type: "error", isLoading: false, autoClose: 2000 });
                toast.error("Failed to generate response.");
            }
        }
        setUserInput("");
    }

    useEffect(() => {
        // Fetch chat history when userId is available
        if (userId) {
            fetchChatHistoryByUserId();
        }
    }, [userId]);


    const fetchChatHistoryByUserId = async () => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`  // Add the token in the Authorization header
                },
                body: JSON.stringify({ user_id: `${userId}`, email: `${useremail}`, subscription: `${sanitizedSubscription}` })
            };
            const response = await fetch('https://vaani.aionasset.in/fetchdata', requestOptions);

            if (!response.ok) {
                throw new Error('Failed to fetch chat history');
            }

            const data = await response.json();
            console.log('all chat with uid : ', data);
            // Group messages by `cid`
            const groupedByCid = data.reduce((acc, message) => {
                const { cid } = message;
                if (!acc[cid]) {
                    acc[cid] = [];
                }
                acc[cid].push(message);
                return acc;
            }, {});

            // Sort messages within each cid by `time` in descending order
            const sortedGroupedData = Object.keys(groupedByCid).map(cid => {
                return {
                    cid,
                    messages: groupedByCid[cid].sort((a, b) => new Date(b.time) - new Date(a.time))
                };
            });

            console.log('Grouped and sorted messages by cid:', sortedGroupedData);
            setHistory(sortedGroupedData);

        } catch (error) {
            console.error('Error fetching chat history:', error);
        }
    };

    // Function to handle multiple file uploads
    const handleFileUpload = async () => {
        const fileInput = document.createElement('input');
        let toastUpload;
        fileInput.type = 'file';
        fileInput.multiple = true; // Allow multiple file selection

        fileInput.onchange = async (e) => {
            const files = Array.from(e.target.files);
            setUploadedFiles(prevFiles => [...prevFiles, ...files]); // Add new files to the existing list

            try {
                console.log('Uploading files:', files);
                let cid = chatId;
                if (!chatId) {
                    cid = uuidv4();
                    console.log('Generated chatId:', cid);
                    setChatId(cid);
                }
                const formData = new FormData();

                // Loop through each file and append it to the FormData object
                files.forEach((file, index) => {
                    const sizeInMB = (file.size / 1024 / 1024).toFixed(2);
                    console.log('File size is:', sizeInMB);
                    if (sizeInMB < 25) {
                        formData.append(`file_${index}`, file);
                    } else {
                        toast.error(`File size too large: ${file.name}`);
                        throw new Error('Failed to upload file');
                    }
                });

                formData.append("user_id", userId);
                formData.append("email", useremail);
                formData.append("chat_id", cid);
                toastUpload = toast.loading("Please wait, file is uploading...");

                // Send the files to the backend
                const response = await fetch("https://vaani.aionasset.in/upload_advance", {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${token}` // Authorization header can be added here
                    },
                    body: formData, // Send FormData directly as the body
                });

                if (response.ok) {
                    const result = await response.json();
                    toast.update(toastUpload, { render: "Uploaded Successfully", type: "success", isLoading: false, autoClose: 500 });
                    console.log("Files uploaded successfully:", result);
                } else {
                    toast.update(toastUpload, { render: "Failed to upload file!", type: "error", isLoading: false, autoClose: 2000 });
                    console.error("Error uploading files:", response.statusText);
                }
            } catch (error) {
                toast.update(toastUpload, { render: "Failed to upload file!", type: "error", isLoading: false, autoClose: 2000 });
                console.error("Error uploading files:", error);
            }
        };

        fileInput.click();
    };


    // Function to remove a specific file by index
    const handleRemoveFile = (fileIndex) => {
        setUploadedFiles(prevFiles => prevFiles.filter((_, index) => index !== fileIndex));
    };

    const handleKeyDown = (e) => {
        // If Enter is pressed without the Ctrl key, send the message
        if (e.key === 'Enter' && !e.ctrlKey) {
            e.preventDefault(); // Prevents adding a new line
            handleSend();
        }

        // If Ctrl + Enter is pressed, allow a new line
        if (e.key === 'Enter' && e.ctrlKey) {
            console.log("hi ctrl enter click hua");
        }
    };

    const handleSelectChange = (event) => {
        const selectedCid = event.target.value;
        if (selectedCid === 'New Chat') {
            console.log("this is click for new chat");
            setUploadedFiles([]);
            setUserInput("");
            setChatId();
            setChatHistory([]);
            setChatId(uuidv4());
            setMessages([]);
        } else {
            console.log("Selected CID:", selectedCid);
            setChatId(selectedCid);
            setChatHistory([]);
            // Filter messages by selected chat ID (cid)
            const filteredMessages = history.filter(message => message.cid === selectedCid);

            // Sort messages by `time` in descending order
            const sortedMessages = filteredMessages.sort((a, b) => new Date(b.time) - new Date(a.time));

            console.log('Filtered and sorted messages by cid:', sortedMessages);
            setMessages(sortedMessages);
        }
    };

    const handleChatDelete = async () => {
        if (!chatId) {
            toast.error("Failed to delete chat");
        } else {
            try {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`  // Add the token in the Authorization header
                    },
                    body: JSON.stringify({ user_id: `${userId}`, email: `${useremail}`, subscription: `${sanitizedSubscription}`, chat_id: `${chatId}` })
                };
                const response = await fetch('https://vaani.aionasset.in/deleteChat', requestOptions);

                if (!response.ok) {
                    throw new Error('Failed to delete chat');
                }

                const data = await response.json();
                toast.error(data.response);
                setHistory([]);
                setTimeout(() => {
                    setMessages([]);
                    fetchChatHistoryByUserId();
                }, 2000); // 2000 milliseconds = 2 seconds

            } catch (error) {
                console.log("something went wrong :", error);
            }
        }
    }

    const formatResponse = (response) => {
        // console.log('response', response);
        if (response) {
            return response.replace(/([0-9]+)\.\s/g, '\n$1. ');
        }
    };

    const handleDownload = (image) => {
        try {
            const uniqueImageName = `img_${uuidv4()}.jpg`; // Create a unique name using UUID
            saveAs(image, uniqueImageName); // Save the image with the unique name
        } catch (e) {
            console.log("Error occured : ", e);
        }
    };

    const handleOpenImage = (image) => {
        try {
            console.log('open function call hua');
            setImage(image);
            setIsOpen(true);
        } catch (e) {
            console.log("Error occured : ", e);
        }
    };


    return (
        <div className="assistant-panel dark:bg-dark-750">
            <ToastContainer
                position="top-left"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <button className="assistant-title" onClick={handleChatDelete}>
                <FontAwesomeIcon icon={faTrash} className="mr-[10%]"
                    title="Delete" />
            </button>
            {/* <button className="assistant-title" onClick={handleClearChat}>
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
            </button> */}
            <i className="fas fa-times close-icon" onClick={onClose}></i>
            <select
                id="language"
                name="language"
                className="w-[95%] ml-1 mr-1 p-1 bg-gray-700 text-white rounded cursor-pointer"
                onChange={handleSelectChange}
            >
                <option value="New Chat">New Chat</option>
                {history
                    .slice()
                    .sort((a, b) => new Date(b.messages[0].res_time) - new Date(a.messages[0].res_time))
                    .map((chat) => {
                        const questionPreview = chat.messages[0].question.split(" ").slice(0, 6).join(" ");
                        return (
                            <option key={chat.cid} value={chat.cid} className="option-text">
                                {new Date(chat.messages[0].res_time).toLocaleDateString()} - {questionPreview}...
                            </option>
                        );
                    })}
            </select>

            <div className="chat-container">
                <div className="bot-message">Vaani : How can I assist you today?</div>
                {messages.length > 0 && (
                    messages.map(chat => (
                        <>
                            {
                                chat.messages.map((message, index) => (
                                    <>
                                        <div
                                            key={message.mid}
                                            className="user-message"
                                        >
                                            {message.question}
                                        </div>
                                        <div className="bot-message" key={index}>
                                            {message.image && (
                                                <FaDownload
                                                    onClick={() => handleDownload(message.image)} // Call handleDownload when icon is clicked
                                                    style={{ cursor: 'pointer', fontSize: '18px', marginLeft: '10px', paddingBottom: '5px' }} // Add custom styles
                                                />
                                            )}
                                            {message.image && (
                                                <div onClick={() => handleOpenImage(message.image)}>
                                                    <img
                                                        src={message.image}
                                                        alt=""
                                                        style={{ maxWidth: '100%', cursor: 'pointer' }}
                                                    />
                                                </div>
                                            )}
                                            <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                                {formatResponse(message.response)}
                                            </ReactMarkdown>
                                        </div>
                                    </>
                                ))
                            }
                        </>
                    ))
                )}
                {isOpen && (
                    <Lightbox
                        mainSrc={image}
                        onCloseRequest={() => setIsOpen(false)}
                    />
                )}

                {chatHistory.length > 0 && (
                    chatHistory.map((chat, index) => (
                        <>
                            <div
                                key={chat.id}
                                className="user-message"
                            >
                                {chat.message}
                            </div>
                            <div className="bot-message">
                                {chat.image && (
                                    <FaDownload
                                        onClick={() => handleDownload(chat.image)} // Call handleDownload when icon is clicked
                                        style={{ cursor: 'pointer', fontSize: '18px', marginLeft: '10px' }} // Add custom styles
                                    />
                                )}

                                {chat.image && (
                                    <div onClick={() => handleOpenImage(chat.image)}>
                                        <img
                                            src={chat.image}
                                            alt=""
                                            style={{ maxWidth: '100%', cursor: 'pointer' }}
                                        />
                                    </div>
                                )}
                                <ReactMarkdown remarkPlugins={[remarkGfm]} key={index}>
                                    {formatResponse(chat.response)}
                                </ReactMarkdown>
                            </div>
                        </>
                    ))
                )}
            </div>
            <div className="file_show">
                {uploadedFiles.length > 0 && uploadedFiles.map((file, index) => (
                    <div key={index} className="file-item">
                        {file.name}
                        <i
                            className="fas fa-times close-icon_file"
                            onClick={() => handleRemoveFile(index)} // Remove file on click
                        ></i>
                    </div>
                ))}
            </div>
            <div className="input-container">
                <input
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="Ask a question..."
                    className="chat-input"
                />
                <i className="fas fa-paperclip file-upload-icon" onClick={handleFileUpload}></i>
                <i className="fas fa-paper-plane send-icon" onClick={handleSend}></i>
            </div>
        </div>
    );
}

export default AssistantPanel;

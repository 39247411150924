import React, { useState, useEffect } from "react";
import "./MainContent.css"; // Make sure to add custom styling
import AddDataModal from "./AddDataModal"; // Import the modal component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { faChevronRight, faNetworkWired, faDatabase, faGlobe, faServer, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';

function MainContent() {
    // State to handle toggle of each section
    const [sections, setSections] = useState({
        fileUploads: false,
        apiEndpoints: false,
        backends: false,
        rssFeeds: false,
        websites: false,
        databases: false,
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [allData, setAllData] = useState([]); // State to store all fetched data
    const cred = JSON.parse(sessionStorage.getItem("cred"));
    const userId = cred.uid;
    const useremail = cred.email;
    const token = sessionStorage.getItem('yariyan_gal');
    let language = sessionStorage.getItem('lang');
    const subscription = sessionStorage.getItem('subpurpose');
    const sanitizedSubscription = subscription.replace(/^\//, ''); // Remove the leading '/'
    console.log("sub : ", sanitizedSubscription);

    // Toggle function for each section
    const toggleSection = (section) => {
        setSections((prev) => ({ ...prev, [section]: !prev[section] }));
    };


    // Fetch all data with uid and auth token
    const fetchData = async () => {
        try {
            const response = await fetch("https://vaani.aionasset.in/allAdvanceData", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}` // Include the auth token in the header
                },
                body: JSON.stringify({ userId, useremail }) // Send uid in the request body
            });

            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }

            const data = await response.json();
            console.log('dtasta : ', data.response);
            setAllData(Array.isArray(data.response) ? data.response : []); // Set only if it’s an array
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Fetch data only once when the component mounts
    useEffect(() => {
        fetchData();
    }, []);

    // Render items based on non-null properties
    const renderFileUploads = () => allData.filter(item => item.fileName || item.filePath);
    // const renderBackends = () => allData.filter(item => item.backend_name || item.backend_url);
    const renderApiEndpoints = () => allData.filter(item => item.endpoint_name || item.endpoint_url);
    const renderWebsites = () => allData.filter(item => item.web_name || item.website_link);
    const renderDatabases = () => allData.filter(item => item.host || item.db_name);

    const handleAddButtonClick = () => {
        setIsModalOpen(true); // Show the modal
    };

    const handleCloseModal = () => {
        setIsModalOpen(false); // Hide the modal
    };

    const handleDelete = async (id) => {
        console.log("id :", id);
        try {
            const response = await fetch("https://vaani.aionasset.in/DeleteAdvance", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}` // Include the auth token in the header
                },
                body: JSON.stringify({ userId, useremail, id }) // Send uid in the request body
            });

            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }

            const data = await response.json();
            if (data.response === 'success') {
                toast.success("Data delete successfully!")
                fetchData();

            } else {
                toast.error("Failed to delete");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    return (
        <div className="main-content_adv">
            <ToastContainer position="top-left" autoClose={3000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
            <h3>Data Connectors</h3>
            <p>
                Add any custom data or database for performing task.
            </p>
            <button className="add-button" onClick={handleAddButtonClick}>
                + Add Data
            </button>
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-container">
                        <AddDataModal onClose={handleCloseModal} />
                    </div>
                </div>
            )}
            <div className="data-sections">
                <div className="section">
                    <div className="section-title" onClick={() => toggleSection("fileUploads")}>
                        <FontAwesomeIcon icon={faChevronRight} style={{ marginRight: '8px', color: '#4A90E2' }} />
                        <FontAwesomeIcon icon={faFile} style={{ marginRight: '8px', color: '#4A90E2' }} />
                        File Uploads
                    </div>
                    {sections.fileUploads && (
                        <div className="section-content">
                            {renderFileUploads().map((item, index) => {
                                const extension = item.fileName ? item.fileName.split('.').pop().toUpperCase() : 'No extension';
                                return (
                                    <div key={index} className="file-item">
                                        <div className="file-header">
                                            <span className="file-extension">{extension} </span>
                                            <span className="file-description">{item.fileName}</span>
                                        </div>
                                        <div className="file-details">
                                            this file conatain a list of sql commands to execute
                                        </div>
                                        <div className="file-actions">
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                                className="action-icon"
                                                onClick={() => handleDelete(item.id)}  // Wrap in an anonymous function
                                                title="Delete"
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>

                <div className="section">
                    <div className="section-title" onClick={() => toggleSection("apiEndpoints")}>
                        <FontAwesomeIcon icon={faChevronRight} style={{ marginRight: '8px', color: '#4A90E2' }} />
                        <FontAwesomeIcon icon={faNetworkWired} style={{ marginRight: '8px', color: '#4A90E2' }} />
                        API Endpoints
                    </div>
                    {sections.apiEndpoints && (
                        <div className="section-content">
                            {renderApiEndpoints().map((item, index) => (
                                <div key={index} className="file-item">
                                    <div className="file-header">
                                        <span className="file-extension">{item.endpoint_name} </span>
                                        <span className="file-description">{item.endpoint_url}</span>
                                    </div>
                                    <div className="file-details">
                                        this file conatain a list of sql commands to execute
                                    </div>
                                    <div className="file-actions">
                                        <FontAwesomeIcon
                                            icon={faTrash}
                                            className="action-icon"
                                            onClick={() => handleDelete(item.id)}  // Wrap in an anonymous function
                                            title="Delete"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                {/* <div className="section">
                    <div className="section-title" onClick={() => toggleSection("backends")}>
                        <FontAwesomeIcon icon={faChevronRight} style={{ marginRight: '8px', color: '#4A90E2' }} />
                        <FontAwesomeIcon icon={faServer} style={{ marginRight: '8px', color: '#4A90E2' }} />
                        Backends
                    </div>
                    {sections.backends && (
                        <div className="section-content">
                            {renderBackends().map((item, index) => (
                                <div key={index} className="file-item">
                                    <div className="file-header">
                                        <span className="file-extension">{item.backend_name} </span>
                                        <span className="file-description">{item.backend_url}</span>
                                    </div>
                                    <div className="file-details">
                                        this file conatain a list of sql commands to execute
                                    </div>
                                    <div className="file-actions">
                                        <FontAwesomeIcon
                                            icon={faTrash}
                                            className="action-icon"
                                            onClick={() => handleDelete(item.id)}  // Wrap in an anonymous function
                                            title="Delete"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div> */}
                <div className="section">

                    <div className="section-title" onClick={() => toggleSection("websites")}>
                        <FontAwesomeIcon icon={faChevronRight} style={{ marginRight: '8px', color: '#4A90E2' }} />
                        <FontAwesomeIcon icon={faGlobe} style={{ marginRight: '8px', color: '#4A90E2' }} />
                        Websites
                    </div>
                    {sections.websites && (
                        <div className="section-content">
                            {renderWebsites().map((item, index) => (
                                <div key={index} className="file-item">
                                    <div className="file-header">
                                        <span className="file-extension">{item.web_name} </span>
                                        <span className="file-description">{item.website_link}</span>
                                    </div>
                                    <div className="file-details">
                                        this file conatain a list of sql commands to execute
                                    </div>
                                    <div className="file-actions">
                                        <FontAwesomeIcon
                                            icon={faTrash}
                                            className="action-icon"
                                            onClick={() => handleDelete(item.id)}  // Wrap in an anonymous function
                                            title="Delete"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    <div className="section">
                        <div className="section-title" onClick={() => toggleSection("databases")}>
                            <FontAwesomeIcon icon={faChevronRight} style={{ marginRight: '8px', color: '#4A90E2' }} />
                            <FontAwesomeIcon icon={faDatabase} style={{ marginRight: '8px', color: '#4A90E2' }} />
                            Databases
                            <span className="pro-feature">Pro Feature</span>
                        </div>
                        {sections.databases && (
                            <div className="section-content">
                                {renderDatabases().map((item, index) => (
                                    <div key={index} className="file-item">
                                        <div className="file-header">
                                            <span className="file-extension">{item.host} </span>
                                            <span className="file-description">{item.db_name}</span>
                                        </div>
                                        <div className="file-details">
                                            this file conatain a list of sql commands to execute
                                        </div>
                                        <div className="file-actions">
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                                className="action-icon"
                                                onClick={() => handleDelete(item.id)}  // Wrap in an anonymous function
                                                title="Delete"
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainContent;
